import { useState, useEffect, useReducer } from 'react'
import { type Dayjs } from 'dayjs'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
	Dialog,
	DialogContent,
	TextField,
	Divider,
	DialogTitle,
	Typography,
	CircularProgress,
	IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import {
	deleteReservation,
	postReservation,
	updateReservation,
} from 'api/reservationApi'
import { getUserSubscriptionsByUser } from 'api/subscriptionApi'
import ClientSelector from './ClientSelector'
import UserSubscriptionSelectionList from './UserSubscriptionSelectionList'
import { StyledTimePicker } from './StyledTimePicker'
import { useSnackbar } from 'notistack'
import reservationReducer from 'utils/reservationReducer'
import {
	Client,
	Reservation,
	UserSubscription,
	WorkplaceConfigEntry,
} from 'types'
import ColorSelector, { ColorOptions } from './ColorSelector'
import { useMutation, useQueryClient } from 'react-query'
import { useQuery } from 'react-query'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import minMax from 'dayjs/plugin/minMax'
import { useCurrentUser } from 'utils/UserContext'
import PhoneNumberTextField from './PhoneNumberTextField'
import { Box } from '@mui/system'
import {
	getRealReservationDuration,
	getRealReservationPrice,
} from 'utils/resevationUtils'
import { useReservationStatuses } from 'hooks/useReservationStatuses'
import { ReservationStatusSelector } from './ReservationStatusSelector'
import { useWorkplaces } from 'hooks/useWorkplaces'
import { usePaymentTypes } from 'hooks/usePaymentTypes'
import { ReservationPaymentTypeSelector } from './ReservationPaymentTypeSelector'
import WorkplaceConfigEntrySelector from './WorkplaceConfigEntrySelector'
import { getWorkplaceConfig } from 'api/workplaceApi'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(minMax)

dayjs.tz.setDefault('Asia/Yekaterinburg')

const DialogContentBlock = styled.div`
  min-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

export const ReservationDialog: React.FC<{
	reservationObject: Reservation | null
	dialogOpen: boolean
	handleDialogClose: any
}> = (props) => {
	const queryClient = useQueryClient()
	const [reservation, dispatch] = useReducer(reservationReducer, {
		reservation_start: dayjs().second(0).millisecond(0),
		reservation_end: '',
		commentary: '',
		workplace: '',
		user_subscription: null,
		user: null,
		calendar_color: '',
		admin_info: {},
	})
	const [reservationDate, setReservationDate] = useState<Dayjs | null>(dayjs())
	const { enqueueSnackbar } = useSnackbar()

	const minReservationTime = dayjs().hour(8).minute(0).second(0).millisecond(0)
	const maxReservationTime = dayjs().hour(22).minute(0).second(0).millisecond(0)

	const reservationStatusesQuery = useReservationStatuses()
	const workplaceQuery = useWorkplaces()
	const paymentTypesQuery = usePaymentTypes()

	const workplaceConfigQuery = useQuery(
		['workplaceConfig', reservationDate],
		() => {
			if (reservationDate !== null && reservationDate.isValid())
				return getWorkplaceConfig(
					reservationDate.tz('Asia/Yekaterinburg', true).format("YYYY-MM-DD")
				)
		}
	)

	const { currentUser } = useCurrentUser()

	const fetchUserSubscriptions = async (user_id?: number | null) => {
		if (!user_id) return

		return await getUserSubscriptionsByUser(user_id)
	}

	const clientSubscriptionsQuery = useQuery(
		['userSubscriptions', reservation.user?.id],
		() => fetchUserSubscriptions(reservation.user?.id)
	)

	const handleSelectedWorkplaceChange = (workplaceId: number) => {
		dispatch({ type: 'setWorkplace', payload: { workplaceId: workplaceId } })
	}

	const handleClientChange = (value: Client) => {
		dispatch({ type: 'setUser', payload: { user: value } })
		dispatch({
			type: 'setUserSubscription',
			payload: { userSubscription: null },
		})
	}

	const validateReservation = () => {
		if (reservation.user === null) {
			throw 'Поле клиента не может быть пустым'
		}

		if (
			reservation.reservation_start === null ||
			reservation.reservation_end === null
		) {
			throw 'Начало или конец записи не выставлены'
		}

		if (
			reservation.reservation_start.minute() % 30 !== 0 ||
			reservation.reservation_end.minute() % 30 !== 0
		) {
			throw 'Начало или конец записи выставлены неверно'
		}

		if (
			reservation.reservation_end.diff(
				reservation.reservation_start,
				'hour'
			) === 0 &&
			reservation.reservation_end.diff(
				reservation.reservation_start,
				'minute'
			) < 30
		) {
			throw 'Минимальная длительность записи - 30 минут'
		}

		if (
			reservation.admin_info.actual_work_start !== null &&
			!reservation.admin_info.actual_work_start.isValid()
		) {
			throw 'Фактическое время работы выставлено неверно'
		}

		if (
			reservation.admin_info.actual_work_end !== null &&
			!reservation.admin_info.actual_work_end.isValid()
		) {
			throw 'Фактическое время работы выставлен неверно'
		}
	}

	const getTimeToMinutes = (date: Dayjs, time: Dayjs) => {
		return date
			.set('hour', time.hour())
			.set('minute', time.minute())
			.set('second', 0)
			.set('millisecond', 0)
	}

	const handleSave = async () => {
		validateReservation()

		let reservationObject = {
			...reservation,
			reservation_start: getTimeToMinutes(
				reservationDate!,
				reservation.reservation_start
			)
				.tz('Asia/Yekaterinburg', true)
				.toISOString(),
			reservation_end: getTimeToMinutes(
				reservationDate!,
				reservation.reservation_end
			)
				.set('millisecond', 0)
				.tz('Asia/Yekaterinburg', true)
				.toISOString(),
			admin_info: {
				...reservation.admin_info,
			},
			user: reservation.user.id,
			user_subscription: reservation.user_subscription?.id || null,
			payment: {
				...reservation.payment,
				payment_type:
					reservation.payment?.payment_type?.id ||
					reservation.payment.payment_type,
			},
		}

		if (reservation.admin_info.actual_work_start) {
			reservationObject.admin_info.actual_work_start = getTimeToMinutes(
				reservationDate!,
				reservation.admin_info.actual_work_start
			)
				.tz('Asia/Yekaterinburg', true)
				.toISOString()
		}
		if (reservation.admin_info.actual_work_end) {
			reservationObject.admin_info.actual_work_end = getTimeToMinutes(
				reservationDate!,
				reservation.admin_info.actual_work_end
			)
				.tz('Asia/Yekaterinburg', true)
				.toISOString()
		}

		if (reservationObject.admin_info.reservation_status !== 2) {
			reservationObject.user_subscription = null
			reservationObject.payment.payment_type = null
		}

		let result = null

		if (props.reservationObject) {
			reservationObject.id = props.reservationObject.id
			result = await updateReservation(reservationObject, 'PUT')
		} else {
			result = await postReservation(reservationObject, 'POST')
		}

		if (!result.ok) {
			throw 'errow while saving'
		}

		//quit dialog automatically if creating
		if (!props.reservationObject?.id) props.handleDialogClose()
	}

	const handleDelete = async () => {
		let reservationObjectId = props.reservationObject?.id
		if (!reservationObjectId) return

		let result = await deleteReservation(reservationObjectId)

		if (result.ok) {
			props.handleDialogClose()
		} else {
			throw 'Ошибка при удалении записи'
		}
	}

	const reservationCreateUpdateMutation = useMutation(handleSave, {
		onSuccess: () => {
			enqueueSnackbar('Сохранено', { variant: 'success' })
			queryClient.invalidateQueries('reservations')
			queryClient.invalidateQueries('userSubscriptions')
		},
		onError(error: string, variables, context) {
			enqueueSnackbar(error, { variant: 'error' })
		},
	})

	const reservationDeleteMutation = useMutation(handleDelete, {
		onSuccess: () => {
			enqueueSnackbar('Запись удалена', { variant: 'success' })
			queryClient.invalidateQueries('reservations')
			queryClient.invalidateQueries('userSubscriptions')
		},
		onError: (error: string) => {
			enqueueSnackbar(error, { variant: 'error' })
		},
	})

	const shiftTariffIsUsed = () => {
		if (!workplaceQuery.data || !workplaceConfigQuery.data) {
			return false
		}

		let price_per_shift = workplaceConfigQuery.data?.body.workplaces.find((workplace) => {
			return workplace.workplace === reservation.workplace
		})!.price_per_shift!


		let price = getRealReservationPrice(
			reservation,
			workplaceConfigQuery.data?.body.workplaces.find((workplace) => {
				return workplace.workplace === reservation.workplace
			})!.price!,
			price_per_shift
		)

		return price === price_per_shift
	}

	const getFormattedReservationPrice = () => {
		if (!workplaceQuery.data || !workplaceConfigQuery.data) {
			return 'Сумма к оплате: --- ₽'
		}

		let price = getRealReservationPrice(
			reservation,
			workplaceConfigQuery.data?.body.workplaces.find((workplace) => {
				return workplace.workplace === reservation.workplace
			})!.price!,
			workplaceConfigQuery.data?.body.workplaces.find((workplace) => {
				return workplace.workplace === reservation.workplace
			})!.price_per_shift!
		)

		return `Сумма к оплате: ${price} ₽`
	}

	const getFormattedReservationDuration = () => {
		let duration = getRealReservationDuration(reservation)

		return `Длительность: ${duration.hours} ч. ${duration.minutes} м.`
	}

	useEffect(() => {
		if (props.reservationObject) {
			let actual_work_start = null
			let actual_work_end = null

			if (props.reservationObject.admin_info?.actual_work_start) {
				actual_work_start = dayjs(
					props.reservationObject.admin_info.actual_work_start
				).tz('Asia/Yekaterinburg')
			}
			if (props.reservationObject.admin_info?.actual_work_end) {
				actual_work_end = dayjs(
					props.reservationObject.admin_info.actual_work_end
				).tz('Asia/Yekaterinburg')
			}

			dispatch({
				type: 'reset',
				payload: {
					id: props.reservationObject.id,
					user: props.reservationObject.user,
					workplace: props.reservationObject.workplace.id,
					reservation_start: dayjs(
						props.reservationObject.reservation_start
					).tz('Asia/Yekaterinburg'),
					reservation_end: dayjs(props.reservationObject.reservation_end).tz(
						'Asia/Yekaterinburg'
					),
					user_subscription: props.reservationObject.user_subscription,
					admin_info: {
						...props.reservationObject.admin_info,
						actual_work_start: actual_work_start,
						actual_work_end: actual_work_end,
						reservation_status:
							props.reservationObject.admin_info.reservation_status,
					},
					commentary: props.reservationObject.commentary,
					payment: props.reservationObject.payment,
				},
			})
			setReservationDate(dayjs(props.reservationObject.reservation_start))
		} else {
			dispatch({
				type: 'reset',
				payload: {
					reservation_start: null,
					reservation_end: null,
					admin_info: {
						commentary: '',
						calendar_color: '',
						actual_work_start: null,
						actual_work_end: null,
						reservation_status: 1,
					},
					workplace: workplaceQuery.data?.body![0].id || 0,
					user_subscription: null,
					user: null,
					payment: {
						payment_type: null,
						cost: null,
					},
				},
			})

			setReservationDate(dayjs())
		}
	}, [
		props.reservationObject,
		props.dialogOpen,
		workplaceQuery.data?.body,
		reservationStatusesQuery.data?.body,
	])
	useEffect(() => { }, [reservation.admin_info?.actual_work_start])

	if (
		!reservationStatusesQuery.data ||
		!workplaceQuery.data ||
		!paymentTypesQuery.data
	)
		return null

	return (
		<>
			<Dialog
				maxWidth='xl'
				open={props.dialogOpen}
				onClose={props.handleDialogClose}
			>
				<DialogTitle>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Typography fontSize={'large'}>Запись</Typography>
						<Box>
							{props.reservationObject?.id && currentUser?.is_superuser && (
								<LoadingButton
									loading={reservationDeleteMutation.isLoading}
									onClick={() => reservationDeleteMutation.mutate()}
									variant='contained'
									color='error'
									style={{ marginRight: '10px' }}
									disabled={
										!currentUser.is_superuser &&
										props.reservationObject?.creation_type.id == 2
									}
								>
									Удалить запись
								</LoadingButton>
							)}
							<LoadingButton
								loading={reservationCreateUpdateMutation.isLoading}
								onClick={() => reservationCreateUpdateMutation.mutate()}
								variant='contained'
							>
								Сохранить
							</LoadingButton>
							<IconButton
								style={{ marginLeft: '10px' }}
								onClick={props.handleDialogClose}
							>
								<CloseIcon />
							</IconButton>
						</Box>
					</Box>
				</DialogTitle>
				<Divider />
				<DialogContent>
					<Box
						sx={{
							display: 'flex',
							columnGap: '20px',
						}}
					>
						<Box>
							<DialogContentBlock>
								<ReservationStatusSelector
									value={reservation.admin_info.reservation_status}
									onChange={(event, newValue) => {
										if (newValue !== null) {
											dispatch({
												type: 'setReservationStatus',
												payload: {
													reservationStatus: newValue,
													userSubscription: reservation.userSubscription,
													payementType:
														reservation.payment?.payment_type?.id ||
														reservation.payment?.payment_type,
												},
											})
										}
									}}
								/>
								<ClientSelector
									disabled={props.reservationObject?.creation_type.id == 2}
									handleChange={handleClientChange}
									autocompleteValue={reservation.user}
								/>
								<PhoneNumberTextField
									disabled={props.reservationObject?.creation_type.id == 2}
									value={reservation.user?.phone_number || ''}
								/>
								<TextField
									disabled={props.reservationObject?.creation_type.id == 2}
									InputLabelProps={{
										shrink: true,
									}}
									required
									label='Email'
									fullWidth
									value={reservation.user?.email || ''}
								/>
							</DialogContentBlock>
							<Divider style={{ marginTop: '8px', marginBottom: '3px' }} />
							<DialogContentBlock>
								<Typography variant='subtitle1'>Услуги</Typography>
								{workplaceConfigQuery.data && (
									<WorkplaceConfigEntrySelector
										workplaceConfigEntries={
											workplaceConfigQuery.data?.body.workplaces
										}
										selectedWorkplace={reservation.workplace}
										handleSelectedWorkplaceChange={
											handleSelectedWorkplaceChange
										}
										fullWidth
									/>
								)}
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DesktopDatePicker
										disabled={props.reservationObject?.creation_type.id == 2}
										inputFormat='DD/MM/YYYY'
										label='Дата аренды'
										value={reservationDate}
										onChange={setReservationDate}
										renderInput={(params) => <TextField {...params} />}
									/>
									<Box>
										<Typography
											variant='subtitle1'
											sx={{ marginBottom: '3px' }}
										>
											Время брони:
										</Typography>

										<Box
											sx={{
												display: 'flex',
												columnGap: '20px',
											}}
										>
											<StyledTimePicker
												disabled={
													props.reservationObject?.creation_type.id == 2
												}
												label='Начало'
												value={reservation.reservation_start}
												onChange={(value: Dayjs | null) => {
													if (value?.isValid())
														value = value.set('second', 0).set('millisecond', 0)

													dispatch({
														type: 'setReservationStart',
														payload: {
															reservationStart: value,
														},
													})
												}}
												allowedPeriod={30}
												minValue={minReservationTime}
												maxValue={maxReservationTime}
											/>
											<StyledTimePicker
												disabled={
													props.reservationObject?.creation_type.id == 2
												}
												label='Конец'
												value={reservation.reservation_end}
												onChange={(value: Dayjs | null) => {
													if (value?.isValid())
														value = value.set('second', 0).set('millisecond', 0)

													dispatch({
														type: 'setReservationEnd',
														payload: {
															reservationEnd: value,
														},
													})
												}}
												allowedPeriod={30}
												minValue={reservation.reservation_start?.add(
													30,
													'minutes'
												)}
												maxValue={maxReservationTime}
											/>
										</Box>
									</Box>
									{reservation.id && (
										<Box>
											<Typography
												variant='subtitle1'
												sx={{ marginBottom: '3px' }}
											>
												Фактическое время работы:
											</Typography>
											<Box
												sx={{
													display: 'flex',
													columnGap: '20px',
												}}
											>
												<StyledTimePicker
													label='Начало'
													value={reservation.admin_info.actual_work_start}
													onChange={(value: Dayjs | null) => {
														if (value?.isValid())
															value = value
																.set('second', 0)
																.set('millisecond', 0)

														dispatch({
															type: 'setActualWorkStart',
															payload: {
																actualWorkStart: value,
															},
														})
													}}
													maxValue={maxReservationTime}
													minValue={minReservationTime}
												/>
												<StyledTimePicker
													label='Конец'
													value={reservation.admin_info.actual_work_end}
													onChange={(value: Dayjs | null) => {
														if (value?.isValid())
															value = value
																.set('second', 0)
																.set('millisecond', 0)

														dispatch({
															type: 'setActualWorkEnd',
															payload: {
																actualWorkEnd: value,
															},
														})
													}}
													maxValue={maxReservationTime}
													minValue={minReservationTime}
												/>
											</Box>
										</Box>
									)}
									<Box>
										{reservation.reservation_start &&
											reservation.reservation_end && (
												<Box sx={{ display: 'flex', marginBottom: '10px' }}>
													<Box>
														<Typography ml={'5px'}>
															{reservation.reservation_end.diff(
																reservation.reservation_start
															) >= 0 && getFormattedReservationDuration()}
														</Typography>
														{!reservation.payment.special_cost && (
															<Typography ml={'5px'}>
																{getFormattedReservationPrice()}
															</Typography>
														)}
														{shiftTariffIsUsed() && (
															<Typography ml={'5px'} width={'200px'} color={'blue'}>
																{"Внимание! Забронирована смена, оплата до начала работы"}
															</Typography>
														)}
													</Box>
													<Box
														sx={{
															marginLeft: 'auto',
														}}
													>
														<TextField
															value={reservation.payment.special_cost}
															onChange={(newValue) =>
																dispatch({
																	type: 'setPaymentSpecialCost',
																	payload: {
																		specialCost: newValue.target.value,
																	},
																})
															}
															disabled={!currentUser?.is_superuser}
															label={'Цена'}
														/>
													</Box>
												</Box>
											)}
										{paymentTypesQuery.data?.body &&
											reservation.admin_info.reservation_status === 2 && (
												<ReservationPaymentTypeSelector
													onChange={(event, newValue) => {
														dispatch({
															type: 'setPaymentType',
															payload: { paymentType: newValue },
														})
													}}
													value={
														reservation.payment?.payment_type?.id ||
														reservation.payment?.payment_type
													}
												/>
											)}
									</Box>
									<TextField
										label='Комментарий мастера'
										multiline
										InputLabelProps={{ shrink: true }}
										value={reservation.commentary}
										name='userCommentary'
										disabled
										onChange={(event) =>
											dispatch({
												type: 'setUserCommentary',
												payload: { commentary: event.target.value },
											})
										}
									/>
									<TextField
										label='Комментарий администратора'
										multiline
										value={reservation.admin_info.commentary}
										name='commentary'
										onChange={(event) =>
											dispatch({
												type: 'setCommentary',
												payload: { commentary: event.target.value },
											})
										}
									/>
									<ColorSelector
										selectedColor={reservation.admin_info?.calendar_color}
										handleSelectedColorChange={(value: ColorOptions) =>
											dispatch({
												type: 'setCalendarColor',
												payload: { calendarColor: value },
											})
										}
										fullWidth={true}
									/>
								</LocalizationProvider>
							</DialogContentBlock>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							{reservation.admin_info.reservation_status ===
								reservationStatusesQuery.data.body[1].id && (
									<Typography>
										Активные абонементы
										{clientSubscriptionsQuery.isLoading && (
											<CircularProgress size={15} />
										)}
									</Typography>
								)}
							<Divider />
							{clientSubscriptionsQuery.data?.body &&
								reservation.admin_info.reservation_status ===
								reservationStatusesQuery.data.body[1].id && (
									<UserSubscriptionSelectionList
										clientSubscriptions={clientSubscriptionsQuery.data.body
											.filter((value) => {
												return (
													value.reservation_subscription.workplace.id ===
													reservation.workplace
												)
											})
											.sort((a, b) => b.id - a.id)}
										selectedSubscription={reservation.user_subscription}
										onChange={(value: UserSubscription) => {
											dispatch({
												type: 'setUserSubscription',
												payload: { userSubscription: value },
											})
										}}
										showNotActive={false}
									/>
								)}
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default ReservationDialog
