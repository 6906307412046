import React from 'react'
import { TimePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import dayjs, { type Dayjs } from 'dayjs'

export const StyledTimePicker: React.FC<{
	label?: string
	value: Dayjs | null
	minValue?: Dayjs
	onChange: any
	allowedPeriod?: number
	disabled?: boolean
	maxValue?: Dayjs
}> = (props) => {
	return (
		<TimePicker
			disabled={props.disabled || false}
			label={props.label || ''}
			value={props.value}
			onChange={(value) => props.onChange(value)}
			ampm={false}
			renderInput={(params) => <TextField {...params} />}
			shouldDisableTime={(timeValue, clockType) => {
				if (clockType === 'minutes' && timeValue % (props.allowedPeriod || 1)) {
					return true
				}

				return false
			}}
			minTime={props.minValue || null}
			maxTime={
				props.maxValue || dayjs().hour(22).minute(0).second(0).millisecond(0)
			}
		/>
	)
}
