import { Reservation } from 'types'
import { type Dayjs } from 'dayjs'

export default function reservationReducer(
	state: Omit<Reservation, 'reservation_start' | 'reservation_end'> & {
		reservation_start: Dayjs | null
		reservation_end: Dayjs | null
	},
	action: { type: string; payload: any }
) {
	switch (action.type) {
		case 'setCommentary': {
			return {
				...state,
				admin_info: {
					...state.admin_info,
					commentary: action.payload.commentary,
				},
			}
		}
		case 'setUserCommentary': {
			return {
				...state,
				commentary: action.payload.commentary,
			}
		}
		case 'setCalendarColor': {
			return {
				...state,
				admin_info: {
					...state.admin_info,
					calendar_color: action.payload.calendarColor,
				},
			}
		}
		case 'setWorkplace': {
			return {
				...state,
				workplace: action.payload.workplaceId,
			}
		}
		case 'setPaymentType': {
			return {
				...state,
				payment: {
					...state.payment,
					payment_type: action.payload.paymentType,
				},
				user_subscription: null,
			}
		}
		case 'setPaymentSpecialCost': {
			return {
				...state,
				payment: {
					...state.payment,
					special_cost: action.payload.specialCost,
				},
			}
		}
		case 'setUser': {
			return {
				...state,
				user: action.payload.user,
			}
		}
		case 'setUserSubscription': {
			return {
				...state,
				user_subscription: action.payload.userSubscription,
				payment: {
					...state.payment,
					payment_type: null,
				},
			}
		}
		case 'setReservationStatus': {
			return {
				...state,
				admin_info: {
					...state.admin_info,
					reservation_status: action.payload.reservationStatus,
				},
			}
		}
		case 'reset': {
			return {
				...action.payload,
			}
		}
		case 'setReservationStart': {
			return {
				...state,
				reservation_start: action.payload.reservationStart,
			}
		}
		case 'setReservationEnd': {
			return {
				...state,
				reservation_end: action.payload.reservationEnd,
			}
		}
		case 'setActualWorkStart': {
			return {
				...state,
				admin_info: {
					...state.admin_info,
					actual_work_start: action.payload.actualWorkStart,
				},
			}
		}
		case 'setActualWorkEnd': {
			return {
				...state,
				admin_info: {
					...state.admin_info,
					actual_work_end: action.payload.actualWorkEnd,
				},
			}
		}
	}
}
