import React, { useState } from 'react'
import {
	Paper,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody,
	TableContainer,
	TextField,
	CircularProgress,
	TablePagination,
	Box,
	Button,
} from '@mui/material'
import styled from 'styled-components'
import { WorkplaceConfig } from 'types'
import { useWorkplaces } from 'hooks/useWorkplaces'
import { useQuery } from 'react-query'
import { getAllWorkplaceConfigs } from 'api/workplaceApi'
import WorkplaceConfigDialog from 'components/WorkplaceConfigDialog'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(timezone)
dayjs.extend(utc)

const StyledTableRow = styled(TableRow)`
  && {
    :hover {
      background-color: lightgray;
      cursor: pointer;
    }
  }
`

const columns = [
	{ field: 'activation_date', headerName: 'Дата активации', width: 150 },
	{ field: 'creation_date', headerName: 'Дата создания', width: 150 },
]

export const WorkplaceConfigMenu: React.FC = () => {
	const [dialogOpen, setDialogOpen] = useState<boolean>(false)
	const [page, setPage] = useState<number>(0)
	const [rowsPerPage, setRowsPerPage] = useState<number>(10)
	const [selectedWorkplaceConfig, setSelectedWorkplaceConfig] =
		useState<WorkplaceConfig>()

	const workplaceConfigsDataQuery = useQuery(
		'workplaceConfigs',
		getAllWorkplaceConfigs,
		{
			onSuccess: (data) => {
				data.body.sort((a, b) =>
					dayjs(a.activation_date).tz('Asia/Yekaterinburg') <
						dayjs(b.activation_date).tz('Asia/Yekaterinburg')
						? 1
						: -1
				)
			},
		}
	)

	const handleDialogOpen = (workplaceConfig: WorkplaceConfig) => {
		setSelectedWorkplaceConfig(workplaceConfig)
		setDialogOpen(true)
	}

	const handleCreateDialogOpen = () => {
		setDialogOpen(true)
		setSelectedWorkplaceConfig(undefined)
		console.log('dawdwa')
	}

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	if (!workplaceConfigsDataQuery.data) return null

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					columnGap: '10px',
					alignItems: 'center',
					m: 1
				}}
			>
				<TextField label='Поиск' />
				<Button variant='contained' onClick={() => handleCreateDialogOpen()}>
					Создать
				</Button>
				{workplaceConfigsDataQuery.isLoading && <CircularProgress />}
			</Box>

			<WorkplaceConfigDialog
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
				workplaceConfig={
					selectedWorkplaceConfig
						? {
							...selectedWorkplaceConfig,
							activation_date: dayjs(
								selectedWorkplaceConfig.activation_date
							).tz('Asia/Yekaterinburg'),
						}
						: undefined
				}
			/>
			<Box>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label="client's table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell>{column.headerName}</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{workplaceConfigsDataQuery.data.body
								?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((workplaceConfig) => (
									<StyledTableRow
										key={workplaceConfig.id}
										onClick={() => handleDialogOpen(workplaceConfig)}
									>
										<TableCell>
											{dayjs(workplaceConfig.activation_date)
												.tz('Asia/Yekaterinburg')
												.format('DD-MM-YYYY')}
										</TableCell>
										<TableCell>
											{dayjs(workplaceConfig.creation_date)
												.tz('Asia/Yekaterinburg')
												.format('HH:mm DD-MM-YYYY')}
										</TableCell>
									</StyledTableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25]}
					onRowsPerPageChange={handleChangeRowsPerPage}
					component='div'
					count={workplaceConfigsDataQuery.data?.body.length || 0}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
				/>
			</Box>
		</>
	)
}

export default WorkplaceConfigMenu
