import { getUsersList } from 'api/userApi'
import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, MenuItem, TextField } from '@mui/material'
import { Client } from 'types'
import { useQuery } from 'react-query'
import { getClientFullName } from 'utils/userUtils'

const ClientSelector: React.FC<{
	autocompleteValue: Client | null
	handleChange: any
	fullWidth?: boolean
	disabled?: boolean
}> = (props) => {
	const [inputValue, setInputValue] = useState('')

	const clientsListQuery = useQuery('clientsList', getUsersList)

	if (clientsListQuery.data === undefined) return null

	return (
		<Box>
			<Autocomplete
				isOptionEqualToValue={(option: Client, value: Client) =>
					option.id === value.id
				}
				value={props.autocompleteValue}
				inputValue={inputValue}
				onInputChange={(event, newValue) => {
					setInputValue(newValue)
				}}
				getOptionLabel={(autocompleteValue) => {
					if (!autocompleteValue) return ''
					else
						return (
							getClientFullName(
								autocompleteValue.first_name,
								autocompleteValue.last_name
							) || autocompleteValue.email
						)
				}}
				onChange={(event, newValue) => {
					props.handleChange(newValue)
				}}
				disabled={props.disabled || false}
				fullWidth={props.fullWidth}
				renderInput={(params) => <TextField {...params} label='Имя клиента' />}
				options={clientsListQuery.data.body}
				renderOption={(props, autocompleteValue, index) => {
					let key = `client-listItem-${index}-${autocompleteValue.id}`
					return (
						<MenuItem {...props} key={key}>
							{getClientFullName(
								autocompleteValue.first_name,
								autocompleteValue.last_name
							) || autocompleteValue.email}
						</MenuItem>
					)
				}}
			/>
		</Box>
	)
}

export default ClientSelector
