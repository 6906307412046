import { sendAuthorizedRequest } from './jwtApi'
import { BASE_URL } from 'api/config'
import {
  ApiResponse,
  Reservation,
  ReservationAdminInfo,
  ReservationStatus,
} from 'types'

export async function getReservations(): Promise<ApiResponse<Array<Reservation>>> {
  let reservations_endpoint_url = BASE_URL + 'booking/reservations/'

  let result = await sendAuthorizedRequest(reservations_endpoint_url)

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}

export async function postReservation(
  reservationObject: object,
  method: string = 'POST'
): Promise<ApiResponse<object>> {
  let reservations_endpoint_url = BASE_URL + 'booking/reservations/'
  
	let fetch_options = {
    method: method,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(reservationObject),
  }

  let result = await sendAuthorizedRequest(
    reservations_endpoint_url,
    fetch_options
  )

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}

export async function updateReservation(
  reservationObject: { id: number },
  method = 'PUT'
): Promise<ApiResponse<object>> {
  let reservations_endpoint_url =
    BASE_URL + `booking/reservations/${reservationObject.id}/`

  let fetch_options = {
    method: method,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(reservationObject),
  }

  let result = await sendAuthorizedRequest(
    reservations_endpoint_url,
    fetch_options
  )

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}


export async function getReservationsByDate(
  date: string
): Promise<ApiResponse<Array<Reservation>>> {
  let reservations_endpoint_url = BASE_URL + `booking/reservations/date/${date}`

  let result = await sendAuthorizedRequest(reservations_endpoint_url)

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}

export async function getReservationsByClient(
  client_id: number
): Promise<ApiResponse<Array<Reservation>>> {
  let reservations_endpoint_url = BASE_URL + `booking/reservations/user/${client_id}`

  let result = await sendAuthorizedRequest(reservations_endpoint_url)

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}

export async function getReservationData(
  resrvationId: number
): Promise<ApiResponse<Array<Reservation>>> {
  let reservation_endpoint_url =
    BASE_URL + `booking/reservations/${resrvationId}/`

  let result = await sendAuthorizedRequest(reservation_endpoint_url)

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}



export async function getReservationStatuses(): Promise<
  ApiResponse<Array<ReservationStatus>>
> {
  let reservation_statuses_endpoint_url =
    BASE_URL + `booking/reservation_statuses`

  let result = await sendAuthorizedRequest(reservation_statuses_endpoint_url)

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}


export async function deleteReservation(
  reservationId: number
): Promise<ApiResponse<object>> {
  let reservation_endpoint_url =
    BASE_URL + `booking/reservations/${reservationId}`

  let fetch_options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  }

  let result = await sendAuthorizedRequest(
    reservation_endpoint_url,
    fetch_options
  )

  let resultBody = null

  try {
    resultBody = await result.json()
  } catch (error) {
    resultBody = null
  }

  return {
    status_code: result.status,
    ok: result.ok,
    body: resultBody,
  }
}

export async function updateReservationAdminInfo(
  adminInfo: ReservationAdminInfo,
): Promise<ApiResponse<object>> {
  let reservations_endpoint_url =
    BASE_URL + `booking/admin_info/${adminInfo.id}/`

  let fetch_options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(adminInfo),
  }

  let result = await sendAuthorizedRequest(
    reservations_endpoint_url,
    fetch_options
  )

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}
