import { useState } from 'react'
import ReservationCell from 'components/ReservationCell'
import styled from 'styled-components'
import { getReservationsByDate } from 'api/reservationApi'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { TextField, Button, CircularProgress, Typography } from '@mui/material'
import ReservationDialog from 'components/ReservationDialog'
import localeRu from 'dayjs/locale/ru'
import WorkplaceSelector from 'components/WorkplaceSelector'
import {
  populateCalendar,
  createRowGrid,
  createColumnGrid,
  createTimeArray,
} from 'utils/calendarUtils'
import { type Dayjs } from 'dayjs'
import {
  Reservation,
  Workplace,
  WorkplaceConfig,
  WorkplaceConfigEntry,
} from 'types'
import { useQuery } from 'react-query'
import { getWorkplaceConfig, getWorkplaces } from 'api/workplaceApi'
import WorkplaceConfigEntrySelector from 'components/WorkplaceConfigEntrySelector'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

const CalendarGrid = styled.div<{
  columnAmount: number
  rowAmount: number
  minRowSize?: string
}>`
  display: grid;
  grid-template-columns:
    repeat(${(props) => props.columnAmount}, minmax(100px, 1fr))
    0;
  grid-template-rows: repeat(
    ${(props) => props.rowAmount},
    minmax(${(props) => props.minRowSize || '36px'}, 1fr)
  );
  grid-gap: 5px;
  margin-top: 10px;
  height: 110vh;
`

export const GridBorderTop = styled.div<{
  gridRowStart: number
  gridRowEnd: number
  columnAmount: number
}>`
  grid-row-start: ${(props) => props.gridRowStart};
  grid-row-end: ${(props) => props.gridRowEnd};
  grid-column-start: 1;
  grid-column-end: ${(props) => props.columnAmount + 1};
  border-top: 2px dashed lightgray;
`

export const GridBorderLeft = styled.div<{
  gridColumnStart: number
  gridColumnEnd: number
  rowAmount: number
}>`
  grid-row-start: 1;
  grid-row-end: ${(props) => props.rowAmount + 1};
  grid-column-start: ${(props) => props.gridColumnStart};
  grid-column-end: ${(props) => props.gridColumnEnd};
  border-left: 2px dashed lightgray;
`

const OuterLayoutGrid = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 100px 1fr;
`

const CalendarToolbar = styled.div`
  display: flex;
  grid-column-start: span 2;
  align-items: center;
  margin-left: 60px;
  column-gap: 20px;
  margin-right: 20px;
`

const TimeListItem = styled(Typography)<{
  gridRowStart: number
  gridRowEnd: number
}>`
  text-align: center;
  line-height: 0.3;
  grid-row-start: ${(props) => props.gridRowStart};
  grid-row-end: ${(props) => props.gridRowEnd};
`

export const ReservationScheduleCalendar: React.FC = () => {
  const [selectedCalendarDate, setSelectedCalendarDate] = useState<Dayjs>(
    dayjs()
  )
  const [selectedCalendarWorkplace, setSelectedCalendarWorkplace] =
    useState<number>(1)
  const [reservationDialogOpen, setReservationDialogOpen] =
    useState<boolean>(false)
  const [reservationObject, setReservationObject] =
    useState<Reservation | null>(null)

  const [rowAmount, setRowAmount] = useState<number>(28 * 3)

  const workplaceQuery = useQuery('workplaces', getWorkplaces, {
    staleTime: Infinity,
    cacheTime: Infinity,
  })

  const workplaceConfigQuery = useQuery(
    ['workplaceConfig', selectedCalendarDate],
    () => getWorkplaceConfig(selectedCalendarDate.tz('Asia/Yekaterinburg', true).format("YYYY-MM-DD")),
    {
      onSuccess: (data) => {
        let workplaceAmount = data.body.workplaces.find(
          (workplaceConfigEntry: WorkplaceConfigEntry) =>
            workplaceConfigEntry.workplace === selectedCalendarWorkplace
        )?.amount

        if (workplaceAmount !== undefined) setColumnAmount(workplaceAmount)
      },
    }
  )

  const [columnAmount, setColumnAmount] = useState<number>(
    workplaceQuery.data?.body.find(
      (workplace: Workplace) => workplace.id === selectedCalendarWorkplace
    )?.amount || 1
  )

  const reservationsQuery = useQuery(
    ['reservations', selectedCalendarDate],
    () => getReservationsByDate(selectedCalendarDate.format('YYYY-MM-DD'))
  )

  const onCalendarDateChange = (date: Dayjs | null) => {
    if (date) setSelectedCalendarDate(date)
  }

  const handleWorkplaceChange = (workplaceId: number) => {
    let workplaceAmount = workplaceConfigQuery.data?.body.workplaces.find(
      (workplaceConfigEntry: WorkplaceConfigEntry) =>
        workplaceConfigEntry.workplace === workplaceId
    )?.amount

    if (workplaceAmount) {
      setColumnAmount(workplaceAmount)
      setSelectedCalendarWorkplace(workplaceId)
    }
  }

  const handleOpeningReservationCreationDialog = (
    reservationObject: Reservation | null
  ) => {
    if (reservationObject) setReservationObject(reservationObject)
    setReservationDialogOpen(true)
  }

  const handleClosingReservationCreationDialog = () => {
    setReservationObject(null)
    setReservationDialogOpen(false)
  }

  if (workplaceQuery.data === undefined) return null

  return (
    <>
      {workplaceQuery.data?.body && (
        <ReservationDialog
          reservationObject={reservationObject}
          dialogOpen={reservationDialogOpen}
          handleDialogClose={handleClosingReservationCreationDialog}
        />
      )}

      <OuterLayoutGrid>
        <CalendarToolbar>
          <LocalizationProvider
            adapterLocale={localeRu}
            dateAdapter={AdapterDayjs}
          >
            <DesktopDatePicker
              inputFormat='DD/MM/YYYY'
              label='Дата'
              value={selectedCalendarDate}
              onChange={onCalendarDateChange}
              renderInput={(params) => (
                <TextField sx={{ width: '200px' }} {...params} />
              )}
            ></DesktopDatePicker>
          </LocalizationProvider>
          {workplaceConfigQuery.data && (
            <WorkplaceConfigEntrySelector
              workplaceConfigEntries={
                workplaceConfigQuery.data?.body.workplaces
              }
              selectedWorkplace={selectedCalendarWorkplace}
              handleSelectedWorkplaceChange={handleWorkplaceChange}
              width={'250px'}
            />
          )}
          {(reservationsQuery.isLoading ||
            reservationsQuery.isRefetching ||
            workplaceConfigQuery.isLoading ||
            workplaceConfigQuery.isRefetching) && (
            <CircularProgress size={40} />
          )}

          <Button
            style={{
              marginLeft: 'auto',
            }}
            onClick={() => handleOpeningReservationCreationDialog(null)}
            variant='contained'
          >
            Создать запись
          </Button>
        </CalendarToolbar>
        <CalendarGrid columnAmount={0} rowAmount={rowAmount} minRowSize={'10px'}>
          {createTimeArray(28).map((x, index) => (
            <TimeListItem
              variant='body1'
              gridRowStart={index * 3 + 1}
              gridRowEnd={(index + 1) * 3 + 1}
              key={index}
            >
              {x}
            </TimeListItem>
          ))}
        </CalendarGrid>
        <CalendarGrid
          columnAmount={columnAmount}
          rowAmount={rowAmount}
          minRowSize={'10px'}
        >
          {createRowGrid(rowAmount / 3, GridBorderTop)}
          {createColumnGrid(columnAmount, GridBorderLeft)}
          {!reservationsQuery.isLoading &&
            workplaceQuery.data?.body &&
            reservationsQuery.data &&
            populateCalendar(
              reservationsQuery.data.body
                .sort((a, b) => b.id - a.id)
                .filter(
                  (reservation) =>
                    reservation.workplace.id === selectedCalendarWorkplace
                ),
              workplaceQuery.data?.body,
              rowAmount,
              columnAmount,
              handleOpeningReservationCreationDialog,
              ReservationCell
            )}
        </CalendarGrid>
      </OuterLayoutGrid>
    </>
  )
}

export default ReservationScheduleCalendar
