import { sendAuthorizedRequest } from './jwtApi'
import { BASE_URL } from 'api/config'
import {
	ApiResponse,
	ReservationBookedPercentageStatisticsPerDay,
	ReservationIncomeStatisticsPerDayPerWorkplace,
} from 'types'

export async function getReservationsStatistics(
	month: number,
	year: number
): Promise<ApiResponse<Array<Array<ReservationIncomeStatisticsPerDayPerWorkplace>>>> {
	let url =
		BASE_URL +
		`statistics/income/reservations_per_day/?year=${year}&month=${month}`

	let result = await sendAuthorizedRequest(url)

	return {
		status_code: result.status,
		ok: result.ok,
		body: await result.json(),
	}
}

export async function getReservationsBookedPercentageStatistics(
	month: number,
	year: number
): Promise<ApiResponse<Array<ReservationBookedPercentageStatisticsPerDay>>> {
	let url =
		BASE_URL +
		`statistics/booked_percentage/?year=${year}&month=${month}`

	let result = await sendAuthorizedRequest(url)

	return {
		status_code: result.status,
		ok: result.ok,
		body: await result.json(),
	}
}
