import InputMask from 'react-input-mask'
import { Box, TextField } from '@mui/material'

const PhoneNumberTextField = (props: {
	value: string
	onChange?: any
	disabled?: boolean
}) => {
	return (
		<Box>
			<InputMask
				onChange={props.onChange}
				mask='+7 (999) 999-99-99'
				value={props.value}
				disabled={props.disabled || false}
			>
				<TextField
					InputLabelProps={{
						shrink: true,
					}}
					required
					label='Номер телефона'
					fullWidth
					name='phone_number'
				/>
			</InputMask>
		</Box>
	)
}

export default PhoneNumberTextField
