import { sendAuthorizedRequest } from './jwtApi'
import { BASE_URL } from 'api/config'
import { ApiResponse, Client, StaffUser } from 'types'

export async function getUserData(
	userId: number
): Promise<ApiResponse<Client>> {
	let user_endpoint_url = BASE_URL + `accounts/users/${userId}/`

	// let result = await sendAuthorizedRequest(
	//     reservations_endpoint_url
	// )

	let result = await sendAuthorizedRequest(user_endpoint_url)

	return {
		status_code: result.status,
		ok: result.ok,
		body: await result.json(),
	}
}

export async function getUsersList(): Promise<ApiResponse<Array<Client>>> {
	let user_list_endpoint_url = BASE_URL + `accounts/users/`

	let result = await sendAuthorizedRequest(user_list_endpoint_url)

	//let result = await fetch(reservation_endpoint_url)

	return {
		status_code: result.status,
		ok: result.ok,
		body: await result.json(),
	}
}

export async function createUser(
	userObject: object
): Promise<ApiResponse<object>> {
	let user_endpoint_url = BASE_URL + `accounts/users/`

	let fetch_options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(userObject),
	}

	let result = await sendAuthorizedRequest(user_endpoint_url, fetch_options)

	return {
		status_code: result.status,
		ok: result.ok,
		body: await result.json(),
	}
}

export async function changeUser(userObject: {
	id: number
}): Promise<ApiResponse<object>> {
	let user_endpoint_url = BASE_URL + `accounts/users/${userObject.id}/`

	let fetch_options = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(userObject),
	}

	let result = await sendAuthorizedRequest(user_endpoint_url, fetch_options)

	return {
		status_code: result.status,
		ok: result.ok,
		body: await result.json(),
	}
}

export async function getSelfData(): Promise<ApiResponse<StaffUser>> {
	let user_self_endpoint_url = BASE_URL + `accounts/users/self/`

	let result = await sendAuthorizedRequest(user_self_endpoint_url)

	return {
		status_code: result.status,
		ok: result.ok,
		body: await result.json(),
	}
}
