import React, { useState } from 'react'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartOptions,
	BarController
} from 'chart.js'
import { Bar, Chart } from 'react-chartjs-2'
import { useQuery } from 'react-query'
import { getReservationsBookedPercentageStatistics, getReservationsStatistics } from 'api/statisticsApi'
import { Box } from '@mui/system'
import dayjs, { Dayjs } from 'dayjs'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { TextField, Typography } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ReservationBookedPercentageStatisticsPerDay, ReservationIncomeStatisticsPerDayPerWorkplace } from 'types'
import { getWorkplaces } from 'api/workplaceApi'
import { IncomePerWorkplaceDoughnut } from 'components/charts/IncomePerWorkplaceDoughnut'
import { BookedPercentageDoughnut } from 'components/charts/BookedPercentageDoughnut'

ChartJS.register(
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	BarController
)

const chartOptions: ChartOptions = {
	responsive: true,
	plugins: {
		legend: {
			position: 'top' as const,
		},
		title: {
			display: true,
			text: 'Статистика по дням',
		},
	},
	scales: {
    y: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
    },
    y1: {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
}

export const ReservationStatistics: React.FC = () => {
	const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs())

	const workplaces = useQuery('workplaces', getWorkplaces)

	const reservationStatisticsQuery = useQuery(
		['reservationStatistics', selectedDate],
		() => {
			if (selectedDate?.isValid()) {
				return getReservationsStatistics(
					selectedDate.month() + 1,
					selectedDate.year()
				)
			}
		},
		{
			refetchOnWindowFocus: false,
			onSuccess: () => { },
		}
	)

	const reservationBookedPercentageStatisticsQuery = useQuery(
		['reservationBookedPercentageStatistics', selectedDate],
		() => {
			if (selectedDate?.isValid()) {
				return getReservationsBookedPercentageStatistics(
					selectedDate.month() + 1,
					selectedDate.year()
				)
			}
		},
		{
			refetchOnWindowFocus: false,
			onSuccess: () => { },
		}
	)

	const getDateLabels = (month: number, year: number) => {
		let startDate = dayjs().month(month).year(year).date(1)

		let daysInMonth = startDate.daysInMonth()

		let labels = []

		for (let i = 0; i < daysInMonth; i++) {
			labels.push(startDate.format('D, ddd'))
			startDate = startDate.add(1, 'day')
		}

		return labels
	}

	const getReservationIncomeSumByDay = (
		data: Array<ReservationIncomeStatisticsPerDayPerWorkplace>
	) => {
		let sum = 0

		data.forEach((x) => (sum += x.sum))

		return sum
	}

	const getReservationBookedPercentageByDayTotal = (
		data: ReservationBookedPercentageStatisticsPerDay
	) => {
		let sum = 0

		data.statistics.forEach((x) => (sum += x.percentage))

		return sum
	}

	const getTotalSumPerMonth = (
	) => {
		let data = reservationStatisticsQuery.data?.body

		if (!data)
			return 0

		let sum = 0

		data.forEach((x) => {
			x.forEach((y) => (sum += y.sum))
		})

		return sum
	}

	const constructIncomeChartData = () => {
		if (!selectedDate?.isValid() || !reservationStatisticsQuery.data?.ok) {
			return {
				labels: [],
				datasets: [
				],
			}
		}
		let percentageData = reservationBookedPercentageStatisticsQuery.data?.body.map((x) =>
			getReservationBookedPercentageByDayTotal(x)
		)

		let incomeData = reservationStatisticsQuery.data?.body.map((x) =>
			getReservationIncomeSumByDay(x)
		)

		return {
			labels: getDateLabels(selectedDate.month(), selectedDate.year()),
			datasets: [
				{
					label: 'Прибыль',
					data: incomeData,
					yAxisId: 'y',
					backgroundColor: 'green',
				},
				{
					label: 'Занятость',
					data: percentageData,
					yAxisId: 'y1',
					backgroundColor: 'blue',
				},
			],
		}
	}

	return (
		<Box
			sx={{
				margin: '10px',
			}}
		>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DesktopDatePicker
					value={selectedDate}
					inputFormat='MM/YYYY'
					label='Дата аренды'
					onChange={setSelectedDate}
					renderInput={(params) => <TextField {...params} />}
				></DesktopDatePicker>
			</LocalizationProvider>
			{reservationStatisticsQuery.data?.body && reservationBookedPercentageStatisticsQuery.data?.body && (
				<Box>
					<Typography>{`Всего за месяц: ${getTotalSumPerMonth() || "---"}`}</Typography>
					<Box display='flex'>
						<Box sx={{ height: '400px', width: '400px' }}>
							<IncomePerWorkplaceDoughnut data={reservationStatisticsQuery.data.body} />
						</Box>
						<Box sx={{ height: '400px', width: '400px' }}>
							<BookedPercentageDoughnut data={reservationBookedPercentageStatisticsQuery.data.body} />
						</Box>

					</Box>
					<Chart type={"bar"} options={chartOptions} data={constructIncomeChartData()} />
				</Box>
			)}
		</Box>
	)
}
