import React, { useState } from 'react'
import {
	TableRow,
	Box,
} from '@mui/material'
import styled from 'styled-components'
import { ReservationSubscription } from 'types'
import { useQuery } from 'react-query'
import dayjs from 'dayjs'
import { getSubscriptions } from 'api/subscriptionApi'
import { SubscriptionDialog } from 'components/SubscriptionsDialog'
import { DataGrid, GridColDef, GridEventListener, GridToolbar, GridToolbarQuickFilter, GridValueGetterParams } from '@mui/x-data-grid'

const columns: GridColDef[] = [
	{
		field: 'workplace.name',
		headerName: 'Рабочее место',
		width: 250,
		valueGetter: (params: GridValueGetterParams) =>
			params.row.workplace.name || "-"
	},
	{ field: 'price', headerName: 'Цена', width: 150 },
	{ field: 'duration', headerName: 'Длительность', width: 150 },
	{ field: 'free_minutes', headerName: 'Минуты', width: 150 },
	{
		field: 'created_at',
		headerName: 'Дата создания',
		width: 200,
		valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
	},
]

export const SubscriptionsList: React.FC = () => {
	const [selectedSubscription, setSelectedSubscription] =
		useState<ReservationSubscription | null>(null)
	const [dialogOpen, setDialogOpen] = useState<boolean>(false)
	const [rowsPerPage, setRowsPerPage] = useState<number>(15)

	const subscriptionsQuery = useQuery('subscriptions', getSubscriptions)

	const handleDialogOpen: GridEventListener<'rowClick'> = (params) => {
		setSelectedSubscription(params.row)
		setDialogOpen(true)
	}

	const handleChangeRowsPerPage = (
		pageSize: number
	) => {
		setRowsPerPage(pageSize)
	}

	return (
		<>
			{selectedSubscription && (
				<SubscriptionDialog
					open={dialogOpen}
					onClose={() => setDialogOpen(false)}
					subscription={selectedSubscription}
				/>
			)}
			<Box>
				<Box sx={{ height: "100vh" }}>
					<DataGrid
						loading={subscriptionsQuery.isLoading}
						rows={subscriptionsQuery.data?.body || []}
						columns={columns}
						disableSelectionOnClick
						initialState={{
							sorting: {
								sortModel: [
									{ field: 'created_at', sort: 'desc' }
								],
							},
						}}
						components={{ Toolbar: GridToolbarQuickFilter }}
						componentsProps={{
							toolbar: {
								variant: 'outlined',
								placeholder: 'Поиск...',
								showQuickFilter: true,
							},
						}}
						rowsPerPageOptions={[15, 25]}
						onPageSizeChange={handleChangeRowsPerPage}
						pageSize={rowsPerPage}
						onRowClick={handleDialogOpen}
					/>
				</Box>
			</Box>
		</>
	)
}

export default SubscriptionsList
