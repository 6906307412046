import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useSnackbar } from 'notistack'

export const SnackbarCloseButton: React.FC<{ id: number }> = (props) => {
	const { closeSnackbar } = useSnackbar()

	return (
		<IconButton onClick={() => closeSnackbar(props.id)} color='inherit'>
			<CloseIcon />
		</IconButton>
	)
}
