import UserSubscriptionCard from './UserSubscriptionCard'
import { UserSubscription } from 'types'
import { Box } from '@mui/system'

export const UserSubscriptionSelectionList: React.FC<{
	selectedSubscription?: UserSubscription | null
	onChange?: any
	clientSubscriptions: Array<UserSubscription>
	showNotActive?: boolean
}> = (props) => {
	const filterSubscriptions = () => {
		let filteredClientSubscriptions = props.clientSubscriptions

		if (!props.showNotActive) {
			filteredClientSubscriptions = filteredClientSubscriptions.filter(
				(subscripiton) =>
					(subscripiton.used_minutes < subscripiton.free_minutes && !subscripiton.expired) ||
					subscripiton.id === props.selectedSubscription?.id
			)
		}

		filteredClientSubscriptions = (filteredClientSubscriptions as any).sort((a: any, b: any) => new Date(a.acquisition_date) < new Date(b.acquisition_date))

		return filteredClientSubscriptions
	}

	return (
		<Box>
			{filterSubscriptions().map((clientSubscription: UserSubscription) => (
				<UserSubscriptionCard
					key={clientSubscription.id}
					userSubscription={clientSubscription}
					marginTop={'5px'}
					backgroundColor={
						props.selectedSubscription?.id === clientSubscription.id
							? '#bbdefb'
							: 'white'
					}
					onClick={() => {
						if (
							!props.selectedSubscription ||
							clientSubscription.id !== props.selectedSubscription?.id
						)
							props.onChange(clientSubscription)
						else props.onChange(null)
					}}
				/>
			))}
		</Box>
	)
}

export default UserSubscriptionSelectionList
