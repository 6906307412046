import { getReservationStatuses } from 'api/reservationApi'
import { useQuery } from 'react-query'

export function useReservationStatuses() {
	return useQuery(
		'reservationStatuses',
		() => getReservationStatuses(),
		{
			staleTime: Infinity,
			cacheTime: Infinity,
		}
	)
}
