import React, { useState } from 'react'
import { Divider, TextField, Stack, Typography } from '@mui/material'
import { createUser } from 'api/userApi'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { useMutation, useQueryClient } from 'react-query'
import InputMask from 'react-input-mask'
import PhoneNumberTextField from 'components/PhoneNumberTextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Box } from '@mui/system'
import { Client } from 'types'
import { Dayjs } from 'dayjs'

export const ClientAddForm: React.FC = () => {
	const { enqueueSnackbar } = useSnackbar()
	const queryClient = useQueryClient()

	const [client, setClient] = useState<Omit<Client, 'id' | 'email_activated' | 'creation_type'>>({
		first_name: '',
		last_name: '',
		patronymic: '',
		email: '',
		phone_number: '',
		specialization: '',
		additional_information: '',
		date_of_birth: null,
	})

	const handleClientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setClient((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}))
	}

	const handlePhoneNumberChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setClient((prev) => ({
			...prev,
			phone_number: event.target.value,
		}))
	}

	const handleCreatingClient = async () => {
		let userObject: any = {
			...client,
		}

		if (client.date_of_birth && !client.date_of_birth.isValid())
			throw 'Дата рождения выставлена неверно'

		let date_of_birth = null

		if (client.date_of_birth?.isValid()) {
			date_of_birth = client.date_of_birth.format('YYYY-MM-DD')
		}

		userObject.date_of_birth = date_of_birth
		userObject.phone_number = userObject.phone_number.replace(/\D/g, '')

		let result = await createUser(userObject)

		if (result.ok) {
			setClient({
				first_name: '',
				last_name: '',
				patronymic: '',
				email: '',
				phone_number: '',
				specialization: '',
				additional_information: '',
				date_of_birth: null,
			})
		} else {
			throw result.body
		}
	}

	const clientMutation = useMutation(handleCreatingClient, {
		onSuccess: () => {
			queryClient.invalidateQueries('client')
			enqueueSnackbar('Клиент создан', { variant: 'success' })
		},
		onError: () => {
			enqueueSnackbar('Ошибка при созданнии клиента', { variant: 'error' })
		},
	})

	return (
		<>
			<Stack spacing={2} maxWidth={500} m={'0 auto'} mt={10}>
				<Typography
					style={{
						fontSize: 'x-large',
					}}
				>
					Добавление клиента
				</Typography>
				<Divider></Divider>
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						justifyContent: 'space-between',
					}}
				>
					<TextField
						label='Имя'
						required
						value={client.first_name}
						name='first_name'
						onChange={handleClientChange}
					/>
					<TextField
						label='Фамилия'
						required
						name='last_name'
						value={client.last_name}
						onChange={handleClientChange}
					/>
				</Box>
				<TextField
					label='Отчество'
					required
					name='patronymic'
					value={client.patronymic}
					onChange={handleClientChange}
				/>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						views={['month', 'day']}
						inputFormat='DD/MM'
						label='Дата рождения'
						value={client.date_of_birth}
						onChange={(value: any) =>
							setClient((prev) => ({ ...prev, date_of_birth: value }))
						}
						renderInput={(params: any) => <TextField {...params} />}
					/>
				</LocalizationProvider>
				<PhoneNumberTextField
					onChange={handlePhoneNumberChange}
					value={client.phone_number}
				/>
				<TextField
					label='Email'
					required
					name='email'
					value={client.email}
					onChange={handleClientChange}
				/>
				<TextField
					label='Специализация'
					name='specialization'
					value={client.specialization}
					onChange={handleClientChange}
				/>
				<TextField
					label='Доп. информация'
					value={client.additional_information}
					name='additional_information'
					onChange={handleClientChange}
				/>
				<LoadingButton
					loading={clientMutation.isLoading}
					fullWidth
					variant='contained'
					onClick={() => clientMutation.mutate()}
				>
					Сохранить
				</LoadingButton>
			</Stack>
		</>
	)
}

export default ClientAddForm
