import React, { useEffect, useState } from 'react'
import {
  Paper,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  TextField,
  CircularProgress,
  TablePagination,
	Box,
} from '@mui/material'
import styled from 'styled-components'
import { Workplace } from 'types'
import WorkplaceDialog from 'components/WorkplaceDialog'
import { useWorkplaces } from 'hooks/useWorkplaces'

const StyledTableRow = styled(TableRow)`
  && {
    :hover {
      background-color: lightgray;
      cursor: pointer;
    }
  }
`

const columns = [
  { field: 'name', headerName: 'Название', width: 150 },
  { field: 'amount', headerName: 'Количество', width: 150 },
  { field: 'price', headerName: 'Цена (час)', width: 150 },
  { field: 'price_per_shift', headerName: 'Цена (смена)', width: 150 },
]

export const WorkplaceList: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [searchByNameField, setSearchByNameField] = useState<string>('')
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
	const [selectedWorkplace, setSelectedWorkplace] = useState<Workplace>()

  const workplacesDataQuery = useWorkplaces()

  const handleDialogOpen = (workplace: Workplace) => {
    setSelectedWorkplace(workplace)
    setDialogOpen(true)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  if (!workplacesDataQuery.data) return null

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          columnGap: '10px',
          alignItems: 'center',
        }}
      >
        {workplacesDataQuery.isLoading && <CircularProgress />}
      </Box>

      {selectedWorkplace && (
        <WorkplaceDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          workplace={selectedWorkplace}
        />
      )}
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="client's table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell>{column.headerName}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {workplacesDataQuery.data.body
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((workplaceData) => (
                  <StyledTableRow
                    key={workplaceData.id}
                    onClick={() => handleDialogOpen(workplaceData)}
                  >
                    <TableCell>{workplaceData.name}</TableCell>
                    <TableCell>{workplaceData.amount}</TableCell>
                    <TableCell>{workplaceData.price}</TableCell>
                    <TableCell>{workplaceData.price_per_shift}</TableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component='div'
          count={workplacesDataQuery.data?.body.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Box>
    </>
  )
}

export default WorkplaceList
