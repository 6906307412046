import { type Dayjs } from 'dayjs'
import dayjs from 'dayjs'

export const getMinDateByTime = (a: Dayjs, b: Dayjs) => {
	a = a.set('date', 1).set('month', 1).set('year', 2000)
	b = b.set('date', 1).set('month', 1).set('year', 2000)

	return dayjs.min(a, b)
}

export const getMaxDateByTime = (a: Dayjs, b: Dayjs) => {
	a = a.set('date', 1).set('month', 1).set('year', 2000)
	b = b.set('date', 1).set('month', 1).set('year', 2000)

	return dayjs.max(a, b)
}
