import { getWorkplaces } from 'api/workplaceApi'
import { useQuery } from 'react-query'

export function useWorkplaces() {
	return useQuery(
		'workplaces',
		() => getWorkplaces(),
		{
			staleTime: Infinity,
			cacheTime: Infinity,
		}
	)
}
