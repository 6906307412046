import dayjs from 'dayjs'
import { type Dayjs } from 'dayjs'
import { Reservation } from 'types'
import { getMinDateByTime, getMaxDateByTime } from './common'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(timezone)

const SHIFT_DURATION_HOURS = 8

export const getRealReservationPrice = (
	reservation: {
		reservation_start: Dayjs
		reservation_end: Dayjs
		admin_info: {
			actual_work_start: Dayjs | null
			actual_work_end: Dayjs | null
		}
	},
	price_per_hour: number,
	price_per_shift: number
) => {
	let start = reservation.reservation_start
	let end = reservation.reservation_end

	let price = calculateReservationPrice(
		start,
		end,
		price_per_hour,
		price_per_shift
	)

	if (parseFloat(price as any) >= parseFloat(price_per_shift as any)) {
		return price_per_shift
	}

	if (
		reservation.admin_info.actual_work_start &&
		reservation.admin_info.actual_work_start.isValid()
	) {
		start = getMinDateByTime(
			reservation.admin_info.actual_work_start.tz('Asia/Yekaterinburg', true),
			reservation.reservation_start
		)
	}
	if (
		reservation.admin_info.actual_work_end &&
		reservation.admin_info.actual_work_end.isValid()
	) {
		end = getMaxDateByTime(
			reservation.admin_info.actual_work_end.tz('Asia/Yekaterinburg', true),
			reservation.reservation_end
		)
	}

	price = calculateReservationPrice(
		start,
		end,
		price_per_hour,
		price_per_shift
	)

	return price
}

export const getRealReservationDuration = (reservation: {
	reservation_start: Dayjs
	reservation_end: Dayjs
	admin_info: {
		actual_work_start: Dayjs | null
		actual_work_end: Dayjs | null
	}
}) => {
	let start = reservation.reservation_start
	let end = reservation.reservation_end

	if (
		reservation.admin_info.actual_work_start &&
		reservation.admin_info.actual_work_start.isValid()
	) {
		start = getMinDateByTime(
			reservation.admin_info.actual_work_start.tz('Asia/Yekaterinburg', true),
			reservation.reservation_start
		)
	}
	if (
		reservation.admin_info.actual_work_end &&
		reservation.admin_info.actual_work_end.isValid()
	) {
		end = getMaxDateByTime(
			reservation.admin_info.actual_work_end.tz('Asia/Yekaterinburg', true),
			reservation.reservation_end
		)
	}

	let duration = calculateDuration(start, end)

	return duration
}

export function calculateDuration(start: Dayjs, end: Dayjs) {
	start = start.set('date', 1).set('month', 1).set('year', 2000)
	end = end.set('date', 1).set('month', 1).set('year', 2000)

	let hours = end.diff(start, 'hour')
	let minutes = end.diff(start, 'minute') % 60

	return { hours: hours, minutes: minutes }
}

export const calculateReservationPrice = (
	start: Dayjs,
	end: Dayjs,
	price: number,
	price_per_shift: number
) => {
	if (end < start) {
		return '---'
	}

	start = start.set('date', 1).set('month', 1).set('year', 2000)
	end = end.set('date', 1).set('month', 1).set('year', 2000)

	let reservationDurationMinutes = Math.floor(
		Math.abs(end.diff(start, 'minute'))
	)

	let reservationDurationHalfHours = Math.floor(reservationDurationMinutes / 30)

	let price_per_half_hour = price / 2

	let reservationPrice = reservationDurationHalfHours * price_per_half_hour

	if (reservationDurationMinutes % 30 !== 0) {
		reservationPrice = reservationPrice + price / 2
	}

	if (reservationDurationMinutes <= 60) {
		return price
	}

	return reservationPrice
}
