import React, { useState } from 'react'
import {
  Drawer as MuiDrawer,
  ListItemButton,
  ListItemText,
  List,
  Collapse,
  ListItemIcon,
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import LogoutIcon from '@mui/icons-material/Logout'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import styled from 'styled-components'
import { useCurrentUser } from 'utils/UserContext'
import { Box } from '@mui/system'
import { useLocation, useNavigate } from 'react-router'

const StyledDrawer = styled(MuiDrawer)`
  && {
    width: ${(props: { width: string }) => props.width};
    & .MuiDrawer-paper {
      width: ${(props) => props.width};
      box-sizing: border-box;
    }
    height: 100%;
  }
`

export const Drawer: React.FC<{ width: string }> = (props) => {
  const [openSubscriptions, setOpenSubsriptions] =
    React.useState<boolean>(false)
  const [openClients, setOpenClients] = React.useState<boolean>(false)
  const [openWorkplaces, setOpenWorkplaces] = React.useState<boolean>(false)
  const [openStatistics, setOpenStatistics] = React.useState<boolean>(false)
  const [selectedDrawerItem, setSelectedDrawerItem] = React.useState('')

  const { currentUser } = useCurrentUser()
  const location = useLocation()

  const navigate = useNavigate()

  const handleOpenSubscriptions = () => {
    setOpenSubsriptions(!openSubscriptions)
  }

  const handleOpenWorkplaces = () => {
    setOpenWorkplaces(!openWorkplaces)
  }

  const handleOpenClients = () => {
    setOpenClients(!openClients)
  }

  const handleOpenStatistics = () => {
    setOpenStatistics(!openStatistics)
  }

  if (currentUser === undefined) return null

  return (
    <StyledDrawer variant='permanent' anchor='left' width={props.width}>
      <List>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '98vh',
          }}
        >
          <Box>
            <ListItemButton onClick={handleOpenClients}>
              <ListItemIcon>
                <PersonIcon></PersonIcon>
              </ListItemIcon>
              <ListItemText primary='Клиенты' />
              {openClients ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openClients} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <ListItemButton
                  selected={location.pathname === '/clients/new/'}
                  sx={{ pl: 4 }}
                  onClick={() => navigate('/clients/new/', { replace: true })}
                >
                  <ListItemText primary='Добавить клиента' />
                </ListItemButton>
                <ListItemButton
                  selected={location.pathname === '/clients/list/'}
                  sx={{ pl: 4 }}
                  onClick={() => navigate('/clients/list/', { replace: true })}
                >
                  <ListItemText primary='Просмотр клиентов' />
                </ListItemButton>
              </List>
            </Collapse>

            {currentUser.is_superuser && (
              <React.Fragment>
                <ListItemButton onClick={handleOpenSubscriptions}>
                  <ListItemIcon>
                    <CardMembershipIcon></CardMembershipIcon>
                  </ListItemIcon>
                  <ListItemText primary='Абонементы' />
                  {openSubscriptions ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openSubscriptions} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    {currentUser.is_superuser && (
                      <ListItemButton
                        selected={location.pathname === '/subscriptions/new/'}
                        sx={{ pl: 4 }}
                        onClick={() =>
                          navigate('/subscriptions/new/', { replace: true })
                        }
                      >
                        <ListItemText primary='Создать абонемент' />
                      </ListItemButton>
                    )}
                    <ListItemButton
                      sx={{ pl: 4 }}
                      selected={
                        location.pathname === '/user_subscriptions/new/'
                      }
                      onClick={() =>
                        navigate('/user_subscriptions/new/', { replace: true })
                      }
                    >
                      <ListItemText primary='Выдать абонемент' />
                    </ListItemButton>
                    {currentUser.is_superuser && (
                      <ListItemButton
                        selected={
                          location.pathname === '/user_subscriptions/list/'
                        }
                        sx={{ pl: 4 }}
                        onClick={() =>
                          navigate('/user_subscriptions/list/', {
                            replace: true,
                          })
                        }
                      >
                        <ListItemText primary='Просмотр купленных абонементов' />
                      </ListItemButton>
                    )}
                    {currentUser.is_superuser && (
                      <ListItemButton
                        selected={location.pathname === '/subscriptions/list/'}
                        sx={{ pl: 4 }}
                        onClick={() =>
                          navigate('/subscriptions/list/', { replace: true })
                        }
                      >
                        <ListItemText primary='Просмотр абонементов' />
                      </ListItemButton>
                    )}
                  </List>
                </Collapse>
              </React.Fragment>
            )}
            {currentUser.is_superuser && (
              <React.Fragment>
                <ListItemButton onClick={handleOpenWorkplaces}>
                  <ListItemIcon>
                    <BusinessCenterIcon></BusinessCenterIcon>
                  </ListItemIcon>
                  <ListItemText primary='Рабочие места' />
                  {openWorkplaces ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                {currentUser.is_superuser && (
                  <Collapse in={openWorkplaces} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        selected={location.pathname === '/workplaces/list/'}
                        sx={{ pl: 4 }}
                        onClick={() =>
                          navigate('/workplaces/list/', { replace: true })
                        }
                      >
                        <ListItemText primary='Просмотр рабочих мест' />
                      </ListItemButton>
                      <ListItemButton
                        selected={location.pathname === '/workplaces/configs/'}
                        sx={{ pl: 4 }}
                        onClick={() =>
                          navigate('/workplaces/configs/', { replace: true })
                        }
                      >
                        <ListItemText primary='План рабочих мест' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            )}
            <ListItemButton
              selected={location.pathname === '/calendar/'}
              onClick={() => navigate('/calendar/', { replace: true })}
            >
              <ListItemIcon>
                <ReceiptLongIcon></ReceiptLongIcon>
              </ListItemIcon>
              <ListItemText primary='Записи' />
            </ListItemButton>
            {currentUser.is_superuser && (
              <React.Fragment>
                <ListItemButton onClick={handleOpenStatistics}>
                  <ListItemIcon>
                    <AnalyticsIcon></AnalyticsIcon>
                  </ListItemIcon>
                  <ListItemText primary='Статистика' />
                  {openStatistics ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openStatistics} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    <ListItemButton
                      selected={
                        location.pathname === '/statistics/reservations/'
                      }
                      sx={{ pl: 4 }}
                      onClick={() =>
                        navigate('/statistics/reservations/', { replace: true })
                      }
                    >
                      <ListItemText primary='Брони' />
                    </ListItemButton>
                  </List>
                </Collapse>
              </React.Fragment>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
            <ListItemIcon>
              <LogoutIcon></LogoutIcon>
            </ListItemIcon>
            <ListItemButton
              onClick={() => navigate('/logout/', { replace: true })}
            >
              <ListItemText primary='Выйти' />
            </ListItemButton>
          </Box>
        </Box>
      </List>
    </StyledDrawer>
  )
}

export default Drawer
