import React, { useContext, useState, useEffect, createContext } from 'react'
import { StaffUser } from 'types'
import { getSelfData } from 'api/userApi'

const UserContext = createContext<
  | {
      currentUser: StaffUser | undefined
      currentUserLoading: boolean
    }
  | undefined
>(undefined)

function UserContextProvider({ children }: { children: React.ReactNode }) {
  // Initialize state
  const [currentUser, setCurrentUser] = useState<StaffUser | undefined>()
  const [currentUserLoading, setCurrentUserLoading] = useState<boolean>(true)

  // Fetch data
  useEffect(() => {
    const fetchWorkplaceOptions = async () => {
      let fetchResult = await getSelfData()
      setCurrentUser(fetchResult.body)
      setCurrentUserLoading(false)
    }

		if (!currentUser)
    	fetchWorkplaceOptions()
  }, [])

  return (
    <UserContext.Provider value={{ currentUser, currentUserLoading }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContextProvider

// Create a hook to use the APIContext, this is a Kent C. Dodds pattern
export function useCurrentUser() {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('Context must be used within a Provider')
  }
  return context
}
