import React, { useEffect, useState } from 'react'
import {
	Dialog,
	DialogContent,
	TextField,
	Divider,
	DialogTitle,
	Typography,
	Box,
	Checkbox,
	FormControlLabel,
} from '@mui/material'
import styled from 'styled-components'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { WorkplaceConfig, WorkplaceConfigEntry } from 'types'
import { useMutation, useQueryClient } from 'react-query'
import {
	changeWorkplaceConfig,
	createWorkplaceConfig,
	deleteWorkplaceConfig,
} from 'api/workplaceApi'
import { useWorkplaces } from 'hooks/useWorkplaces'
import {
	DataGrid,
	GridCellEditCommitParams,
	GridColDef,
	GridValueGetterParams,
} from '@mui/x-data-grid'
import dayjs, { Dayjs } from 'dayjs'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(timezone)
dayjs.extend(utc)

const DialogContentBlock = styled.div`
  min-width: 760px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

const WorkplaceConfigDialog: React.FC<{
	workplaceConfig:
	| (Omit<WorkplaceConfig, 'activation_date'> & {
		activation_date: Dayjs
	})
	| undefined
	open: boolean
	onClose: any
}> = (props) => {
	const [workplaceConfig, setWorkplaceConfig] = useState<
		Omit<WorkplaceConfig, 'activation_date'> & { activation_date: Dayjs }
	>(
		props.workplaceConfig || {
			id: 0,
			workplaces: [],
			activation_date: dayjs(),
			creation_date: '',
			one_day_only: false,
		}
	)

	const { enqueueSnackbar } = useSnackbar()

	const workplacesQuery = useWorkplaces()

	const queryClient = useQueryClient()

	const columns: GridColDef[] = [
		{
			field: 'workplace',
			headerName: 'Рабочее место',
			sortable: false,
			width: 160,
			valueGetter: (params: GridValueGetterParams) => {
				return `${workplacesQuery.data?.body.find((x) => x.id == params.row.workplace)!
					.name
					}`
			},
		},
		{
			field: 'amount',
			type: 'number',
			headerName: 'Количество',
			width: 150,
			editable: true,
		},
		{
			field: 'price',
			type: 'number',
			headerName: 'Цена (час)',
			width: 150,
			editable: true,
		},
		{
			field: 'price_per_shift',
			headerName: 'Цена (смена)',
			type: 'number',
			width: 150,
			editable: true,
		},
	]

	const handleWorkplaceConfigChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setWorkplaceConfig((prev) => ({
			...prev,
			[event.target.name]: event.target.checked,
		}))
	}

	const handleWorkplaceConfigActivationDateChange = (value: any) => {
		setWorkplaceConfig({
			...workplaceConfig,
			activation_date: value,
		})
	}

	const handleSave = async () => {
		if (workplaceConfig.id === undefined) {
			throw 'Ошибка'
		}
		let workplaceObject: any = {
			...workplaceConfig,
			activation_date: workplaceConfig.activation_date
				.set('hour', 0)
				.set('minute', 0)
				.set('second', 0)
				.set('millisecond', 0)
				.tz('Asia/Yekaterinburg', true)
				.toISOString(),
		}

		delete workplaceObject.creation_date

		let result = null
		try {
			if (workplaceObject.id)
				result = await changeWorkplaceConfig(workplaceObject)
			else result = await createWorkplaceConfig(workplaceObject)
		} catch {
			throw 'Ошибка сети'
		}
		if (result?.ok) {
		} else {
			throw 'Не удалось сохранить данные о рабочем месте'
		}

		if (!props.workplaceConfig?.id) props.onClose()
	}

	const handleDelete = async () => {
		if (workplaceConfig.id === undefined) {
			throw 'Ошибка'
		}

		let result = null

		try {
			result = await deleteWorkplaceConfig(workplaceConfig.id)
		} catch {
			throw 'Ошибка сети'
		}
		if (result?.ok) {
		} else {
			throw 'Не удалось удалить план рабочих мест.'
		}
	}

	const workplaceConfigMutation = useMutation(handleSave, {
		onSuccess: () => {
			enqueueSnackbar('Сохранено', { variant: 'success' })
			queryClient.invalidateQueries('workplaceConfigs')
		},
		onError: (error: string) => {
			enqueueSnackbar(error, { variant: 'error' })
		},
	})

	const workplaceConfigDeleteMutation = useMutation(handleDelete, {
		onSuccess: () => {
			enqueueSnackbar('Удалено', { variant: 'success' })
			queryClient.invalidateQueries('workplaceConfigs')
			props.onClose()
		},
		onError: (error: string) => {
			enqueueSnackbar(error, { variant: 'error' })
		},
	})

	const onCellChange = (params: GridCellEditCommitParams) => {
		let workplaces = JSON.parse(JSON.stringify(workplaceConfig.workplaces))

		workplaces.find((x: WorkplaceConfigEntry) => x.workplace == params.id)[
			params.field
		] = params.value

		setWorkplaceConfig({
			...workplaceConfig,
			workplaces,
		})
	}

	useEffect(() => {
		if (!props.open) {
			setWorkplaceConfig({
				id: 0,
				workplaces: [],
				activation_date: dayjs(),
				creation_date: '',
				one_day_only: false,
			})
		} else if (props.workplaceConfig) {
			setWorkplaceConfig(props.workplaceConfig)
		} else {
			setWorkplaceConfig({
				id: 0,
				workplaces: workplacesQuery.data!.body.map((x) => {
					return {
						workplace: x.id,
						amount: 0,
						price: 0,
						price_per_shift: 0,
						id: 0,
					}
				}),
				activation_date: dayjs(),
				creation_date: '',
				one_day_only: false,
			})
		}
	}, [props.open, workplacesQuery.data])

	return (
		<>
			<Dialog open={props.open} onClose={props.onClose} maxWidth='xl'>
				<DialogTitle>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Typography fontSize={'large'}>План рабочих мест</Typography>
						<Box>
							<LoadingButton
								loading={
									workplaceConfigMutation.isLoading ||
									workplaceConfigDeleteMutation.isLoading
								}
								onClick={() => workplaceConfigDeleteMutation.mutate()}
								variant='contained'
								color='error'
								sx={{ marginRight: '10px' }}
							>
								Удалить
							</LoadingButton>
							<LoadingButton
								loading={
									workplaceConfigMutation.isLoading ||
									workplaceConfigDeleteMutation.isLoading
								}
								onClick={() => workplaceConfigMutation.mutate()}
								variant='contained'
							>
								Сохранить
							</LoadingButton>
						</Box>
					</Box>
				</DialogTitle>
				<Divider />
				<DialogContent style={{ paddingTop: 12, minHeight: '400px' }}>
					<DialogContentBlock
						style={{ maxHeight: '700px' }}
					>
						<Box sx={{ display: 'flex', flexDirection: "wrap", justifyContent: 'space-between' }} >
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DesktopDatePicker
									inputFormat='DD/MM/YYYY'
									label='Дата активации'
									value={workplaceConfig.activation_date}
									onChange={handleWorkplaceConfigActivationDateChange}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
							<FormControlLabel
								label='Только один день'
								control={
									<Checkbox
										name='one_day_only'
										onChange={handleWorkplaceConfigChange}
										checked={workplaceConfig.one_day_only}
									/>
								}
							></FormControlLabel>
						</Box>

						<DataGrid
							autoHeight
							getRowId={(row) => row.workplace}
							onCellEditCommit={onCellChange}
							rows={workplaceConfig.workplaces}
							columns={columns}
							hideFooterPagination
							hideFooterSelectedRowCount
						/>
					</DialogContentBlock>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default WorkplaceConfigDialog
