import { sendAuthorizedRequest } from './jwtApi'
import { BASE_URL } from 'api/config'
import { ApiResponse, ReservationSubscription, UserSubscription } from 'types'

export async function postSubscription(
  subscriptionObject: object,
  method = 'POST'
): Promise<ApiResponse<object>> {
  let subscription_endpoint_url = BASE_URL + 'booking/subscriptions/'

  let fetch_options = {
    method: method,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(subscriptionObject),
  }

  let result = await sendAuthorizedRequest(
    subscription_endpoint_url,
    fetch_options
  )

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}

export async function getSubscriptions(): Promise<
  ApiResponse<Array<ReservationSubscription>>
> {
  let subscription_endpoint_url = BASE_URL + 'booking/subscriptions/'

  let fetch_options = {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  }

  let result = await sendAuthorizedRequest(
    subscription_endpoint_url,
    fetch_options
  )
  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}

export async function changeSubscription(
  subscriptionObject: any,
): Promise<ApiResponse<ReservationSubscription>> {
  let subscription_endpoint_url = BASE_URL + `booking/subscriptions/${subscriptionObject.id}/`

  let fetch_options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(subscriptionObject),
  }

  let result = await sendAuthorizedRequest(
    subscription_endpoint_url,
    fetch_options
  )

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}

export async function getUserSubscriptionsByUser(
  userId: number
): Promise<ApiResponse<Array<UserSubscription>>> {
  let user_subscription_endpoint_url =
    BASE_URL + `booking/user_subscriptions/user/${userId}`

  let fetch_options = {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  }

  let result = await sendAuthorizedRequest(
    user_subscription_endpoint_url,
    fetch_options
  )

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}

export async function getUserSubscriptions(): Promise<
  ApiResponse<Array<UserSubscription>>
> {
  let user_subscription_endpoint_url =
    BASE_URL + `booking/user_subscriptions/`

  let fetch_options = {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  }

  let result = await sendAuthorizedRequest(
    user_subscription_endpoint_url,
    fetch_options
  )

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}

export async function postUserSubscription(
  userSubscriptionObject: object,
  method = 'POST'
): Promise<ApiResponse<object>> {
  let user_subscription_endpoint_url = BASE_URL + 'booking/user_subscriptions/'

  let fetch_options = {
    method: method,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(userSubscriptionObject),
  }

  let result = await sendAuthorizedRequest(
    user_subscription_endpoint_url,
    fetch_options
  )

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}


export async function deleteUserSubscription(
  userSubscriptionId: number,
): Promise<ApiResponse<object>> {
  let user_subscription_endpoint_url = BASE_URL + `booking/user_subscriptions/${userSubscriptionId}`

  let fetch_options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  }

  let result = await sendAuthorizedRequest(
    user_subscription_endpoint_url,
    fetch_options
  )

  return {
    status_code: result.status,
    ok: result.ok,
    body: {},
  }
}
