import React, { useState } from 'react'
import {
	Divider,
	TextField,
	Button,
	Stack,
	InputAdornment,
	Typography,
} from '@mui/material'
import WorkplaceSelector from 'components/WorkplaceSelector'
import { postSubscription } from 'api/subscriptionApi'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'

export const SubscriptionAddForm: React.FC = () => {
	const [subscription, setSubscription] = useState<{
		free_minutes: number
		workplace: null | number
		price: number
		duration: number
	}>({
		free_minutes: 0,
		workplace: null,
		price: 0,
		duration: 0,
	})
	const { enqueueSnackbar } = useSnackbar()

	const handleSubscriptionChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setSubscription((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}))
	}

	const validateInput = () => {
		if (subscription.workplace === null)
			throw 'Рабочее место должно быть заполнено'

		if (subscription.free_minutes === 0)
			throw 'Кол-во часов должно быть больше 0'

		if (subscription.price === 0) throw 'Цена должна быть больше 0'

		if (subscription.duration === 0) throw 'Длительность должна быть больше 0'
	}

	const handleCreatingSubscription = async () => {
		let subscriptionObject = {
			...subscription,
		}

		validateInput()

		let result = null

		try {
			result = await postSubscription(subscriptionObject)
		} catch {
			throw 'Не удалось создать абонемент'
		}

		if (!result?.ok) {
			throw 'Не удалось создать абонемент'
		}
	}

	const queryClient = useQueryClient()

	const subscriptionMutation = useMutation(handleCreatingSubscription, {
		onSuccess: () => {
			queryClient.invalidateQueries('reservationSubscriptions')
			enqueueSnackbar('Абонемент создан', { variant: 'success' })
		},
		onError: (error: string) => {
			enqueueSnackbar(error, { variant: 'error' })
		},
	})

	const handleWorkplaceChange = (value: number) => {
		setSubscription((prev) => ({
			...prev,
			workplace: value,
		}))
	}

	const validateNumberInput = (value: string) => {
		let formattedString = ''

		value = value.trim()

		for (let i = 0; i < value.length; i++) {
			let char = value[i]
			if (!isNaN(Number(char))) {
				formattedString += char
			}
		}

		return Number(formattedString)
	}

	return (
		<Stack spacing={2} maxWidth={500} m={'0 auto'} mt={10}>
			<Typography fontSize='x-large'>Добавление абонемента</Typography>
			<Divider />
			<WorkplaceSelector
				selectedWorkplace={subscription.workplace}
				handleSelectedWorkplaceChange={handleWorkplaceChange}
				fullWidth
			/>
			<TextField
				label='Кол-во часов'
				required
				value={subscription.free_minutes / 60}
				onChange={(event) => {
					let maskedInput = validateNumberInput(event.target.value)

					setSubscription((prev) => ({
						...prev,
						free_minutes: maskedInput * 60,
					}))
				}}
			/>
			<TextField
				label='Цена'
				required
				name='price'
				value={subscription.price}
				onChange={(event) => {
					let maskedInput = validateNumberInput(event.target.value)

					setSubscription((prev) => ({
						...prev,
						price: maskedInput,
					}))
				}}
				InputProps={{
					startAdornment: <InputAdornment position='start'>₽</InputAdornment>,
				}}
			/>
			<TextField
				label='Длительность (дни)'
				required
				name='duration'
				onChange={(event) => {
					let maskedInput = validateNumberInput(event.target.value)

					setSubscription((prev) => ({
						...prev,
						duration: maskedInput,
					}))
				}}
				value={subscription.duration}
			/>

			<LoadingButton
				loading={subscriptionMutation.isLoading}
				fullWidth
				variant='contained'
				onClick={() => subscriptionMutation.mutate()}
			>
				Сохранить
			</LoadingButton>
		</Stack>
	)
}

export default SubscriptionAddForm
