import React, { useEffect, useState } from 'react'
import Drawer from 'components/StyledDrawer'
import dayjs from 'dayjs'
import { getSavedJwtTokens } from 'api/jwtApi'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import { SnackbarCloseButton } from 'components/SnackbarCloseButton'
import { AuthorizedRoutes, UnauthorizedRoutes } from 'components/AppRoutes'
import UserContextProvider from 'utils/UserContext'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ruRU } from '@mui/material/locale'
import { Box } from '@mui/material'
import JwtRefresher from 'components/JwtRefresher'

dayjs.locale('ru')

const theme = createTheme(
	{
		typography: {
			fontFamily: 'roboto',
			fontWeightLight: 300,
			fontWeightRegular: 400,
			fontWeightMedium: 500,
			fontWeightBold: 600,
		},
	},
	ruRU
)

const drawerWidth = '220'

const queryClient = new QueryClient()

const App: React.FC = () => {
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
		() => getSavedJwtTokens().access !== null
	)

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<QueryClientProvider client={queryClient}>
					<SnackbarProvider
						maxSnack={5}
						action={(key) => <SnackbarCloseButton id={key as number} />}
					>
						<Box
							sx={{
								minWidth: 'fit-content',
							}}
						>
							<Box
								sx={{
									marginLeft: (isLoggedIn && `${drawerWidth}px`) || '0',
									width:
										(isLoggedIn && `calc(100% - ${drawerWidth}px)`) ||
										'100%',
								}}
							>
								{!isLoggedIn && <UnauthorizedRoutes />}

								{isLoggedIn && (
									<UserContextProvider>
										<JwtRefresher />
										<Drawer width={`${drawerWidth}px`} />
										<AuthorizedRoutes />
									</UserContextProvider>
								)}
							</Box>
						</Box>
					</SnackbarProvider>
				</QueryClientProvider>
			</ThemeProvider>
		</React.Fragment>
	)
}

export default App
