import { DataGrid, GridColDef, GridToolbarQuickFilter, GridValueGetterParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { getUserSubscriptions } from 'api/subscriptionApi'
import { useQuery } from 'react-query'
import { getClientFullName } from 'utils/userUtils'

const columns: GridColDef[] = [
	{
		field: 'name',
		headerName: 'Имя',
		width: 200,
		valueGetter: (params: GridValueGetterParams) =>
			`${getClientFullName(
				params.row.user.first_name,
				params.row.user.last_name
			)}`,
	},
	{
		field: 'workplace',
		headerName: 'Рабочее место',
		width: 175,
		valueGetter: (params: GridValueGetterParams) =>
			`${params.row.reservation_subscription.workplace.name}`,
	},
	{
		field: 'acquisition_price',
		headerName: 'Цена',
		width: 120,
	},
	{
		field: 'remaining_time',
		headerName: 'Остаток',
		width: 150,
		valueGetter: (params: GridValueGetterParams) => {
			let remaining_time_minutes =
				params.row.free_minutes - params.row.used_minutes

			return remaining_time_minutes
		},
		valueFormatter: (params) => `${Math.floor(params.value / 60)} ч. ${params.value % 60} м.`
	},
	{
		field: 'free_minutes',
		headerName: 'Всего',
		width: 150,
		valueFormatter: (params) =>
			`${params.value / 60} ч.`,
	},
	{
		type: 'date',
		field: 'acquisition_date',
		headerName: 'Дата приобретения',
		width: 150,
		valueFormatter: (params) => {
			let date = dayjs(params.value)

			return date.isValid() ? date.format('DD/MM/YYYY') : "-"
		}
	},
	{
		type: 'date',
		field: 'activation_date',
		headerName: 'Дата активации',
		width: 150,
		valueFormatter: (params) => {
			let date = dayjs(params.value)

			return date.isValid() ? date.format('DD/MM/YYYY') : "-"
		}
	},
	{
		type: 'date',
		field: 'deactivation_date',
		headerName: 'Дата деактивации',
		width: 150,
		valueFormatter: (params) => {
			let date = dayjs(params.value)

			return date.isValid() ? date.format('DD/MM/YYYY') : "-"
		}
	},
]

export const ClientSubscriptionsList: React.FC = () => {
	const userSubscriptionsQuery = useQuery(
		'userSubscriptions',
		getUserSubscriptions
	)

	return (
		<div
			style={{
				height: '100vh',
			}}
		>
			<DataGrid
				loading={userSubscriptionsQuery.isLoading}
				autoPageSize
				rows={userSubscriptionsQuery.data?.body || []}
				columns={columns}
				components={{ Toolbar: GridToolbarQuickFilter }}
				initialState={{
					sorting: {
						sortModel: [
							{ field: 'acquisition_date', sort: 'desc' }
						],
					},
				}}
				componentsProps={{
					toolbar: {
						variant: 'outlined',
						placeholder: 'Поиск...',
					},
				}}
				disableSelectionOnClick
				experimentalFeatures={{ newEditingApi: true }}
			/>
		</div>
	)
}

export default ClientSubscriptionsList
