import React, { useEffect, useState } from 'react'
import {
	Dialog,
	DialogContent,
	TextField,
	Divider,
	DialogTitle,
	Typography,
	Stack,
	Box,
} from '@mui/material'
import styled from 'styled-components'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { ReservationSubscription, UserSubscription } from 'types'
import { useMutation, useQueryClient } from 'react-query'
import dayjs from 'dayjs'
import { changeSubscription } from 'api/subscriptionApi'

const DialogContentBlock = styled.div`
  min-width: 760px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

export const SubscriptionDialog: React.FC<{
	subscription: ReservationSubscription
	open: boolean
	onClose: any
}> = (props) => {
	const [subscription, setSubscription] = useState<ReservationSubscription>(
		props.subscription
	)

	const { enqueueSnackbar } = useSnackbar()

	const queryClient = useQueryClient()

	const handleSave = async () => {
		if (!subscription) throw 'error'

		let result = null

		let subscriptionObject = {
			id: subscription.id,
			workplace: subscription.workplace.id,
			duration: subscription.duration,
			price: subscription.price,
			free_minutes: subscription.free_minutes,
		}

		try {
			result = await changeSubscription(subscriptionObject)
		} catch {
			throw 'Ошибка сети'
		}

		if (result?.ok) {
		} else {
			throw 'Не удалось сохрнить абонемент'
		}
	}

	const subscriptionSaveMutation = useMutation(handleSave, {
		onSuccess: () => {
			enqueueSnackbar('Сохранено', { variant: 'success' })
			queryClient.invalidateQueries('subscriptions')
			props.onClose()
		},
		onError: (error: string) => {
			enqueueSnackbar(error, { variant: 'error' })
		},
	})

	const handleSubscriptionChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setSubscription((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}))
	}

	useEffect(() => {
		if (props.subscription) {
			setSubscription({ ...props.subscription })
		}
	}, [props.subscription])

	return (
		<>
			<Dialog open={props.open} onClose={props.onClose} maxWidth='xl'>
				<DialogTitle>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Typography fontSize={'large'}>Абонемент</Typography>
						<Box sx={{ display: 'flex' }}>
							<LoadingButton
								loading={subscriptionSaveMutation.isLoading}
								onClick={() => subscriptionSaveMutation.mutate()}
								variant='contained'
								color='error'
							>
								Удалить
							</LoadingButton>
							<LoadingButton
								loading={subscriptionSaveMutation.isLoading}
								onClick={() => subscriptionSaveMutation.mutate()}
								variant='contained'
							>
								Сохранить
							</LoadingButton>
						</Box>
					</Box>
				</DialogTitle>
				<Divider />
				<DialogContent style={{ paddingTop: 4 }}>
					<DialogContentBlock>
						<Stack spacing={2}>
							<TextField
								label='Рабочее место'
								disabled
								fullWidth
								value={subscription.workplace.name}
							/>
							<TextField
								label='Доступно минут'
								required
								fullWidth
								name='free_minutes'
								onChange={handleSubscriptionChange}
								value={subscription.free_minutes}
							/>
							<TextField
								label='Длительность'
								required
								name='duration'
								onChange={handleSubscriptionChange}
								value={subscription.duration}
							/>
							<TextField
								label='Цена'
								required
								name='price'
								onChange={handleSubscriptionChange}
								value={subscription.price}
							/>
						</Stack>
					</DialogContentBlock>
				</DialogContent>
			</Dialog>
		</>
	)
}
