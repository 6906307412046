import { getPaymentTypes } from 'api/paymentApi'
import { useQuery } from 'react-query'

export function usePaymentTypes() {
	return useQuery(
		'paymentTypes',
		() => getPaymentTypes(),
		{
			staleTime: Infinity,
			cacheTime: Infinity,
		}
	)
}
