import React, { useState } from 'react'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
} from 'chart.js'
import { useQuery } from 'react-query'
import { Box } from '@mui/system'
import { Doughnut } from 'react-chartjs-2'
import { ReservationIncomeStatisticsPerDayPerWorkplace } from 'types'
import { getWorkplaces } from 'api/workplaceApi'

ChartJS.register(
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
)

export const doughnutOptions = {
	responsive: true,
	plugins: {
		legend: {
			position: 'top' as const,
		},
		title: {
			display: true,
			text: 'Прибыль',
		},
	},
}

export const IncomePerWorkplaceDoughnut: React.FC<{
	data: Array<Array<ReservationIncomeStatisticsPerDayPerWorkplace>>
}> = (props) => {

	const workplaces = useQuery('workplaces', getWorkplaces)

	const getTotalIncomePerMonthPerWorkplace = (
		data: Array<Array<ReservationIncomeStatisticsPerDayPerWorkplace>>
	) => {
		let sumPerWorkplace: Array<{
			workplace_id: number
			sum: 0
		}> = []

		if (data.length > 0) {
			data[0].forEach((x) => {
				sumPerWorkplace.push({
					workplace_id: x.workplace_id,
					sum: 0,
				})
			})
		}

		data.forEach((x) => {
			x.forEach((y) => {
				sumPerWorkplace.find((x) => x.workplace_id == y.workplace_id)!.sum +=
					y.sum
			})
		})

		return sumPerWorkplace
	}

	const constructDoughnutData = () => {
		if (!props.data) {
			return {
				labels: [],
				datasets: [
					{
						label: 'Прибыль',
						data: [],
					},
				],
			}
		}

		let data = getTotalIncomePerMonthPerWorkplace(
			props.data
		)

		return {
			labels: data.map(
				(x) =>
					workplaces.data?.body.find((y) => y.id === x.workplace_id)?.name || ''
			),
			datasets: [
				{
					label: 'Прибыль',
					data: data.map((x) => x.sum),
					backgroundColor: [
						'rgba(255, 99, 132, 0.2)',
						'rgba(54, 162, 235, 0.2)',
						'rgba(255, 206, 86, 0.2)',
						'rgba(75, 192, 192, 0.2)',
						'rgba(153, 102, 255, 0.2)',
						'rgba(255, 159, 64, 0.2)',
					],
					borderColor: [
						'rgba(255, 99, 132, 1)',
						'rgba(54, 162, 235, 1)',
						'rgba(255, 206, 86, 1)',
						'rgba(75, 192, 192, 1)',
						'rgba(153, 102, 255, 1)',
						'rgba(255, 159, 64, 1)',
					],
				},
			],
		}
	}

	return (
		<Box>
			{props.data && (
				<Box>
					<Doughnut data={constructDoughnutData()} options={doughnutOptions}/>
				</Box>
			)}
		</Box>
	)
}
