import React from 'react'
import { TextField, MenuItem } from '@mui/material'
import styled from 'styled-components'

const StyledTextField = styled(TextField)`
  && {
    width: ${(props: { width: string; bgcolor?: string }) => props.width};
  }

  .MuiInputBase-root {
    background-color: ${(props) => props.bgcolor};
  }
`

export enum ColorOptions {
	amethyst = '#9966cc',
	amaranth = '#e52b50',
	blue = '#0171BB',
	gray = '#303030',
	orange = '#fb7341',
	green = '#00a652',
	yellow = '#eec039',
}

const ColorSelector: React.FC<{
	width?: string
	fullWidth?: boolean
	selectedColor: ColorOptions | ''
	handleSelectedColorChange: any
}> = (props) => {
	return (
		<StyledTextField
			size='small'
			select
			bgcolor={props.selectedColor}
			width={props.width || ''}
			fullWidth={props.fullWidth || false}
			value={props.selectedColor || ''}
			onChange={(event: any) =>
				props.handleSelectedColorChange(
					ColorOptions[event.target.value as keyof typeof ColorOptions]
				)
			}
		>
			{(Object.keys(ColorOptions) as Array<keyof typeof ColorOptions>).map(
				(color) => {
					return (
						<MenuItem
							key={color}
							value={color}
							style={{
								backgroundColor: ColorOptions[color],
								marginBottom: '1px',
								padding: '10px',
							}}
						/>
					)
				}
			)}
		</StyledTextField>
	)
}

export default ColorSelector
