export const getClientFullName = (
  first_name?: string,
  last_name?: string,
  patronymic?: string
) => {
	if (!(first_name || last_name || patronymic))
		return null
  last_name = last_name !== null ? last_name : ''
  let fullName = first_name + ' ' + last_name
	return fullName
}
