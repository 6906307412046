import React, { useState } from 'react'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
} from 'chart.js'
import { useQuery } from 'react-query'
import { Box } from '@mui/system'
import { Doughnut } from 'react-chartjs-2'
import { ReservationBookedPercentageStatisticsPerDay } from 'types'
import { getWorkplaces } from 'api/workplaceApi'

ChartJS.register(
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
)

const doughnutOptions = {
	responsive: true,
	plugins: {
		legend: {
			position: 'top' as const,
		},
		title: {
			display: true,
			text: 'Занятость',
		},
	},
}

export const BookedPercentageDoughnut: React.FC<{ data: Array<ReservationBookedPercentageStatisticsPerDay> }> = (props) => {
	const workplaces = useQuery('workplaces', getWorkplaces)

	const getTotalBookedPercentagePerMonthPerWorkplace = (
		data: Array<ReservationBookedPercentageStatisticsPerDay>
	) => {
		let percentageSumPerWorkplace: Array<{
			workplace_id: number
			percentage: number
		}> = []

		if (data.length > 0) {
			data.forEach((statDay) => {
				statDay.statistics.forEach((statPerWorkplace) => {
					if (!percentageSumPerWorkplace.find(p => p.workplace_id == statPerWorkplace.workplace_id)) {
						percentageSumPerWorkplace.push({
							workplace_id: statPerWorkplace.workplace_id,
							percentage: 0,
						})
					}
				})
			})
		}

		data.forEach((x) => {
			x.statistics.forEach((y) => {
				percentageSumPerWorkplace.find((x) => x.workplace_id == y.workplace_id)!.percentage +=
					y.percentage
			})
		})

		percentageSumPerWorkplace.forEach(x => {
			x.percentage = x.percentage / data.filter(y => y.statistics.filter(z => z.workplace_id === x.workplace_id).length > 0).length
		})

		let totalPercentage = percentageSumPerWorkplace.reduce((total, x) => total + x.percentage, 0)

		let remainingPercentage = 1 - totalPercentage

		percentageSumPerWorkplace.push({
			workplace_id: -1,
			percentage: remainingPercentage,
		})

		return percentageSumPerWorkplace
	}

	const constructDoughnutBookedPercentageData = () => {
		if (!props.data) {
			return {
				labels: [],
				datasets: [
					{
						label: 'dataset1',
						data: [],
					},
				],
			}
		}

		let data = getTotalBookedPercentagePerMonthPerWorkplace(
			props.data
		)

		return {
			labels: data.map(
				(x) => {
					if (x.workplace_id === -1) return "Остаток"

					return workplaces.data?.body.find((y) => y.id === x.workplace_id)?.name || ''
				}
			),
			datasets: [
				{
					label: 'Занятость',
					data: data.map((x) => x.percentage),
					backgroundColor: [
						'rgba(255, 99, 132, 0.2)',
						'rgba(54, 162, 235, 0.2)',
						'rgba(255, 206, 86, 0.2)',
						'rgba(75, 192, 192, 0.2)',
						'rgba(153, 102, 255, 0.2)',
						'rgba(255, 159, 64, 0.2)',
					],
					borderColor: [
						'rgba(255, 99, 132, 1)',
						'rgba(54, 162, 235, 1)',
						'rgba(255, 206, 86, 1)',
						'rgba(75, 192, 192, 1)',
						'rgba(153, 102, 255, 1)',
						'rgba(255, 159, 64, 1)',
					],
				},
			],
		}
	}

	return (
		<Box
			sx={{
				margin: '10px',
			}}
		>
			{props.data && (
				<Box>
					<Doughnut data={constructDoughnutBookedPercentageData()} options={doughnutOptions} />
				</Box>
			)}
		</Box>
	)
}
