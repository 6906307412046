import { sendAuthorizedRequest } from './jwtApi'
import { BASE_URL } from 'api/config'
import { ApiResponse, Workplace, WorkplaceConfig } from 'types'
import { Dayjs } from 'dayjs'

export async function getWorkplaces(): Promise<ApiResponse<Array<Workplace>>> {
  let workplacesEndpointUrl = BASE_URL + 'booking/workplaces/'

  let result = await sendAuthorizedRequest(workplacesEndpointUrl)

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}

export async function getWorkplaceConfig(
  date: string
): Promise<ApiResponse<WorkplaceConfig>> {
  let workplacesEndpointUrl =
    BASE_URL + `booking/workplace_config/active?date=${date}`

  let result = await sendAuthorizedRequest(workplacesEndpointUrl)

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}

export async function deleteWorkplaceConfig(
  workplaceConfigId: number
): Promise<ApiResponse<string>> {
  let workplaceConfigsEndpointUrl =
    BASE_URL + `booking/workplace_config/${workplaceConfigId}/`

  let fetchOptions = {
    method: 'DELETE',
    headers: {
    },
  }

  let result = await sendAuthorizedRequest(
    workplaceConfigsEndpointUrl,
    fetchOptions
  )

  return {
    status_code: result.status,
    ok: result.ok,
    body: ""
  }
}

export async function changeWorkplaceConfig(
  workplaceConfigObject: WorkplaceConfig
): Promise<ApiResponse<WorkplaceConfig>> {
  let workplaceConfigsEndpointUrl =
    BASE_URL + `booking/workplace_config/${workplaceConfigObject.id}/`

  let fetchOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(workplaceConfigObject),
  }

  let result = await sendAuthorizedRequest(
    workplaceConfigsEndpointUrl,
    fetchOptions
  )

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}

export async function createWorkplaceConfig(
  workplaceConfigObject: WorkplaceConfig
): Promise<ApiResponse<WorkplaceConfig>> {
  let workplaceConfigsEndpointUrl = BASE_URL + `booking/workplace_config/`

  let fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(workplaceConfigObject),
  }

  let result = await sendAuthorizedRequest(
    workplaceConfigsEndpointUrl,
    fetchOptions
  )

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}

export async function getAllWorkplaceConfigs(): Promise<
  ApiResponse<Array<WorkplaceConfig>>
> {
  let workplacesEndpointUrl = BASE_URL + `booking/workplace_config/`

  let result = await sendAuthorizedRequest(workplacesEndpointUrl)

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}

export async function changeWorkplace(
  workplaceObject: Workplace
): Promise<ApiResponse<Array<Workplace>>> {
  let workplacesEndpointUrl =
    BASE_URL + `booking/workplaces/${workplaceObject.id}/`

  let fetchOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(workplaceObject),
  }

  let result = await sendAuthorizedRequest(workplacesEndpointUrl, fetchOptions)

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}
