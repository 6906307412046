import { useState } from 'react'
import { login } from 'api/jwtApi'
import { Stack, TextField, Typography } from '@mui/material'
import styled from 'styled-components'
import { Container } from '@mui/system'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'

const StyledDiv = styled.div`
  font-size: x-large;
  text-align: left;
  width: 100%;
`

const StyledTextField = styled(TextField)`
  && {
    width: 100%;
    margin-top: 20px;
  }
`

const StyledContainer = styled(Container)`
  && {
    margin-top: 20px;
  }
`

export const Login: React.FC = () => {
	const [username, setUsername] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const { enqueueSnackbar } = useSnackbar()

	const handleUsernameInput = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setUsername(event.target.value)
	}

	const handlePasswordInput = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setPassword(event.target.value)
	}

	const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()
		e.stopPropagation()
		setIsLoading(true)

		let result = await login(username, password)

		if (result.ok) {
			enqueueSnackbar('Успешный вход', { variant: 'success' })
			window.location.href = '/calendar'
		} else {
			enqueueSnackbar('Не удалось войти', { variant: 'error' })
		}
		setIsLoading(false)
	}

	return (
		<StyledContainer maxWidth='sm'>
			<form onSubmit={handleSubmit}>
				<Stack spacing={2} maxWidth={500} m={'0 auto'} mt={10}>
					<Typography fontSize={'x-large'}>Войти</Typography>
					<StyledTextField
						label={'Логин'}
						value={username}
						onChange={handleUsernameInput}
					/>

					<StyledTextField
						label={'Пароль'}
						value={password}
						onChange={handlePasswordInput}
						type='password'
					/>

					<LoadingButton
						loading={isLoading}
						variant='outlined'
						className='button'
						type='submit'
					>
						Войти
					</LoadingButton>
				</Stack>
			</form>
		</StyledContainer>
	)
}

export default Login
