import React, { useEffect, useState } from 'react'
import {
	Dialog,
	DialogContent,
	TextField,
	Divider,
	DialogTitle,
	Typography,
	Tabs,
	Tab,
	Stack,
	Box,
} from '@mui/material'
import styled from 'styled-components'
import { getUserSubscriptionsByUser } from 'api/subscriptionApi'
import UserSubscriptionSelectionList from 'components/UserSubscriptionSelectionList'
import { changeUser } from 'api/userApi'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers'
import { Client, ReservationSubscription, UserSubscription } from 'types'
import { ReservationsList } from './ReservationsList'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import PhoneNumberTextField from './PhoneNumberTextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { getClientFullName } from 'utils/userUtils'
import dayjs from 'dayjs'
import UserSubscriptionDialog from './UserSubscriptionDialog'

const DialogContentBlock = styled.div`
  min-width: 760px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

const TabPanel: React.FC<{
	value: number
	index: number
	children: React.ReactNode
}> = ({ value, index, children }) => {
	return (
		<div hidden={value !== index}>
			{value === index && <div>{children}</div>}
		</div>
	)
}

const ClientDialog = (props: {
	client: Client
	open: boolean
	onClose: any
}) => {
	const [selectedTab, setSelectedTab] = useState<number>(0)
	const [client, setClient] = useState<Client>(props.client)

	const [subscriptionModalOpen, setSubscriptionModalOpen] =
		useState<boolean>(false)
	const [selectedSubscription, setSelectedSubscription] =
		useState<UserSubscription | null>(null)

	const { enqueueSnackbar } = useSnackbar()

	const queryClient = useQueryClient()

	const handleSave = async () => {
		if (client.id === undefined) {
			throw 'Ошибка'
		}

		if (client.date_of_birth && !client.date_of_birth.isValid())
			throw 'Дата рождения выставлена неверно'

		let date_of_birth = null

		if (client.date_of_birth && client.date_of_birth.isValid()) {
			date_of_birth = client.date_of_birth.format('YYYY-MM-DD')
		}

		let clientObject = {
			...client,
			date_of_birth: date_of_birth,
		}

		let result = null

		try {
			result = await changeUser(clientObject)
		} catch {
			throw 'Ошибка сети'
		}

		if (result?.ok) {
		} else {
			throw 'Не удалось сохранить данные о клиенте'
		}
	}

	const clientMutation = useMutation(handleSave, {
		onSuccess: () => {
			enqueueSnackbar('Сохранено', { variant: 'success' })
			queryClient.invalidateQueries('clients')
		},
		onError: (error: string) => {
			enqueueSnackbar(error, { variant: 'error' })
		},
	})

	const clientSubscriptionsQuery = useQuery(
		['clientSubscriptions', props.client],
		() => getUserSubscriptionsByUser(props.client.id)
	)

	useEffect(() => {
		if (!props.open) {
			setClient({
				id: 0,
				first_name: '',
				last_name: '',
				patronymic: '',
				email: '',
				phone_number: '',
				specialization: '',
				additional_information: '',
				email_activated: false,
				creation_type: 1,
			})
		} else if (props.client) {
			let date_of_birth = null
			if (props.client.date_of_birth)
				date_of_birth = dayjs(props.client.date_of_birth)
			setClient({
				...props.client,
				date_of_birth: date_of_birth,
			})
		}
	}, [props.client, props.open])

	const handleClientChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setClient((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}))
	}

	return (
		<>
			{selectedSubscription && (
				<UserSubscriptionDialog
					open={subscriptionModalOpen}
					onClose={() => setSubscriptionModalOpen(false)}
					userSubscription={selectedSubscription}
				/>
			)}
			<Dialog open={props.open} onClose={props.onClose} maxWidth='xl'>
				<DialogTitle>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Typography fontSize={'large'}>
							{getClientFullName(client.first_name, client.last_name)}
						</Typography>
						<LoadingButton
							loading={clientMutation.isLoading}
							onClick={() => clientMutation.mutate()}
							variant='contained'
						>
							Сохранить
						</LoadingButton>
					</Box>
				</DialogTitle>
				<Divider />
				<DialogContent style={{ paddingTop: 4 }}>
					<DialogContentBlock>
						<Tabs
							value={selectedTab}
							onChange={(event, newValue) => setSelectedTab(newValue)}
						>
							<Tab label='Общая информация' />
							<Tab label='Абонементы' />
							<Tab label='История посещений' />
						</Tabs>
						<TabPanel value={selectedTab} index={0}>
							<Stack spacing={2} m={'0 auto'}>
								<Divider></Divider>
								<Box
									sx={{
										display: 'flex',
										width: '100%',
										justifyContent: 'space-between',
									}}
								>
									<TextField
										label='Имя'
										fullWidth
										required
										value={client.first_name}
										name='first_name'
										onChange={handleClientChange}
										style={{
											marginRight: '30px',
										}}
										disabled={
											client.creation_type === 2 ||
											client.email_activated ||
											false
										}
									/>
									<TextField
										fullWidth
										label='Фамилия'
										required
										name='last_name'
										value={client.last_name}
										onChange={handleClientChange}
										disabled={
											client.creation_type === 2 ||
											client.email_activated ||
											false
										}
									/>
								</Box>
								<TextField
									label='Отчество'
									required
									name='patronymic'
									value={client.patronymic}
									onChange={handleClientChange}
									disabled={
										client.creation_type === 2 ||
										client.email_activated ||
										false
									}
								/>
								<PhoneNumberTextField
									value={client.phone_number}
									onChange={handleClientChange}
									disabled={
										client.creation_type === 2 ||
										client.email_activated ||
										false
									}
								/>
								<TextField
									label='Email'
									required
									name='email'
									value={client.email}
									onChange={handleClientChange}
									disabled={
										client.creation_type === 2 ||
										client.email_activated ||
										false
									}
								/>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										views={['month', 'day']}
										inputFormat='DD/MM'
										label='Дата рождения'
										value={client.date_of_birth}
										onChange={(value: any) =>
											setClient((prev) => ({ ...prev, date_of_birth: value }))
										}
										renderInput={(params: any) => <TextField {...params} />}
										disabled={
											client.creation_type === 2 ||
											client.email_activated ||
											false
										}
									/>
								</LocalizationProvider>
								<TextField
									label='Специализация'
									name='specialization'
									multiline
									value={client.specialization}
									onChange={handleClientChange}
									disabled={
										client.creation_type === 2 ||
										client.email_activated ||
										false
									}
								/>
								<TextField
									label='Доп. информация'
									value={client.additional_information}
									multiline
									name='additional_information'
									onChange={handleClientChange}
									disabled={
										client.creation_type === 2 ||
										client.email_activated ||
										false
									}
								/>
							</Stack>
						</TabPanel>
						<TabPanel value={selectedTab} index={1}>
							{clientSubscriptionsQuery.data?.body && (
								<UserSubscriptionSelectionList
									clientSubscriptions={clientSubscriptionsQuery.data?.body}
									onChange={(value: UserSubscription | null) => {
										setSelectedSubscription(value)
										if (value) setSubscriptionModalOpen(true)
									}}
									showNotActive={true}
								></UserSubscriptionSelectionList>
							)}
						</TabPanel>
						<TabPanel value={selectedTab} index={2}>
							<ReservationsList user_id={client.id}></ReservationsList>
						</TabPanel>
					</DialogContentBlock>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default ClientDialog
