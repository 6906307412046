import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { usePaymentTypes } from 'hooks/usePaymentTypes'

export const ReservationPaymentTypeSelector: React.FC<{
	value: number
	onChange: (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		newValue: number
	) => void
}> = (props) => {
	const paymentTypes = usePaymentTypes()

	if (!paymentTypes.data?.body) return null

	return (
		<ToggleButtonGroup
			color='primary'
			size='small'
			fullWidth
			exclusive
			value={props.value}
			onChange={props.onChange}
		>
			<ToggleButton value={paymentTypes.data?.body[0].id}>
				Наличные
			</ToggleButton>
			<ToggleButton value={paymentTypes.data?.body[1].id}>Карта</ToggleButton>
		</ToggleButtonGroup>
	)
}
