import React, { useEffect, useState } from 'react'
import {
	Dialog,
	DialogContent,
	TextField,
	Divider,
	DialogTitle,
	Typography,
	Stack,
	Box,
} from '@mui/material'
import styled from 'styled-components'
import { deleteUserSubscription } from 'api/subscriptionApi'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { UserSubscription } from 'types'
import { useMutation, useQueryClient } from 'react-query'
import dayjs from 'dayjs'

const DialogContentBlock = styled.div`
  min-width: 760px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

export const UserSubscriptionDialog: React.FC<{
	userSubscription: UserSubscription
	open: boolean
	onClose: any
}> = (props) => {
	const [userSubscription, setUserSubscription] = useState<UserSubscription>(
		props.userSubscription
	)

	const { enqueueSnackbar } = useSnackbar()

	const queryClient = useQueryClient()

	const handleDelete = async () => {
		if (!props.userSubscription) throw 'error'

		let result = null

		try {
			result = await deleteUserSubscription(props.userSubscription.id)
		} catch {
			throw 'Ошибка сети'
		}

		if (result?.ok) {
		} else {
			throw 'Не удалось удалить абонемент'
		}
	}

	const userSubscriptionDeleteMutation = useMutation(handleDelete, {
		onSuccess: () => {
			enqueueSnackbar('Удалено', { variant: 'success' })
			queryClient.invalidateQueries('clientSubscriptions')
			props.onClose()
		},
		onError: (error: string) => {
			enqueueSnackbar(error, { variant: 'error' })
		},
	})

	useEffect(() => { }, [props.userSubscription])

	return (
		<>
			<Dialog open={props.open} onClose={props.onClose} maxWidth='xl'>
				<DialogTitle>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Typography fontSize={'large'}>Абонемент</Typography>
						<LoadingButton
							loading={userSubscriptionDeleteMutation.isLoading}
							onClick={() => userSubscriptionDeleteMutation.mutate()}
							variant='contained'
							color='error'
						>
							Удалить
						</LoadingButton>
					</Box>
				</DialogTitle>
				<Divider />
				<DialogContent style={{ paddingTop: 4 }}>
					<DialogContentBlock>
						<Stack spacing={2}>
							<TextField
								label='Рабочее место'
								required
								disabled
								fullWidth
								value={
									props.userSubscription.reservation_subscription.workplace.name
								}
							/>
							<TextField
								label='Использовано минут'
								required
								disabled
								fullWidth
								value={props.userSubscription.used_minutes}
							/>
							<TextField
								label='Дата активации'
								required
								disabled
								value={props.userSubscription.activation_date}
							/>
							<TextField
								label='Дата деактивации'
								required
								disabled
								value={props.userSubscription.deactivation_date}
							/>
							<TextField
								label='Дата покупки'
								required
								disabled
								value={dayjs(props.userSubscription.acquisition_date).format(
									'DD/MM/YYYY'
								)}
							/>
							<TextField
								label='Цена'
								required
								disabled
								value={props.userSubscription.acquisition_price}
							/>
						</Stack>
					</DialogContentBlock>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default UserSubscriptionDialog
