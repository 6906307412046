import React from 'react'
import Box from '@mui/material/Box'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { getReservationsByClient } from 'api/reservationApi'
import dayjs from 'dayjs'
import { useQuery } from 'react-query'
import { calculateDuration } from 'utils/resevationUtils'

const columns: GridColDef[] = [
	{
		field: 'workplace',
		headerName: 'Рабочее место',
		width: 150,
		valueGetter: (params: GridValueGetterParams) =>
			`${params.row.workplace.name}`,
	},
	{
		field: 'payemnt_type',
		headerName: 'Оплата',
		sortable: false,
		width: 120,
		valueGetter: (params: GridValueGetterParams) => {
			if (params.row.user_subscription) {
				return 'абонемент'
			} else if (params.row.payment.payment_type?.name) {
				return params.row.payment.payment_type?.name.toLowerCase()
			} else {
				return 'не оплачено'
			}
		},
	},
	{
		field: 'payemnt_sum',
		headerName: 'Сумма оплаты',
		sortable: false,
		width: 120,
		valueGetter: (params: GridValueGetterParams) =>
			params.row.payment.cost,
		valueFormatter: (params) =>
			`${params.value || '-'}`,
	},
	{
		field: 'duration',
		headerName: 'Продолжительность',
		sortable: false,
		width: 120,
		valueGetter: (params: GridValueGetterParams) =>
			`${calculateDuration(
				dayjs(params.row.reservation_start),
				dayjs(params.row.reservation_end)
			).hours
			} ч. ${calculateDuration(
				dayjs(params.row.reservation_start),
				dayjs(params.row.reservation_end)
			).minutes
			} м.`,
	},
	{
		field: 'reservation_start',
		headerName: 'Дата',
		width: 150,
		valueFormatter: (params) =>
			dayjs(params.value).format('DD-MM-YYYY'),
	},
]

export const ReservationsList: React.FC<{ user_id: number }> = (props) => {
	const activeReservationsQuery = useQuery(
		['activeReservations', props.user_id],
		() => getReservationsByClient(props.user_id)
	)

	return (
		<Box sx={{ height: 400 }}>
			<DataGrid
				loading={activeReservationsQuery.isLoading}
				rows={activeReservationsQuery.data?.body || []}
				columns={columns}
				autoPageSize
				initialState={{
					sorting: {
						sortModel: [
							{ field: 'reservation_start', sort: 'desc' }
						],
					},
				}}
				rowsPerPageOptions={[10]}
				disableSelectionOnClick
				experimentalFeatures={{ newEditingApi: true }}
			/>
		</Box>
	)
}
