import React, { useState, useEffect } from 'react'
import { TextField, MenuItem, Box } from '@mui/material'
import styled from 'styled-components'
import { useWorkplaces } from 'hooks/useWorkplaces'

const StyledTextField = styled(TextField)`
  && {
    width: ${(props: { width: string }) => props.width};
  }
`

export const WorkplaceSelector: React.FC<{
  width?: string
  fullWidth?: boolean
  selectedWorkplace: number | null
  handleSelectedWorkplaceChange: any
  disabled?: boolean
}> = (props) => {
  const workplaceQuery = useWorkplaces()

  if (workplaceQuery.data === undefined) return null

  return (
    <Box>
      {!workplaceQuery.isLoading && (
        <StyledTextField
          label='Тип рабочего места'
          select
          width={props.width || ''}
          fullWidth={props.fullWidth || false}
          value={props.selectedWorkplace || ''}
          onChange={(event) =>
            props.handleSelectedWorkplaceChange(event.target.value)
          }
          disabled={props.disabled || false}
        >
          {workplaceQuery.data.body.map((workplace) => {
            return (
              <MenuItem value={workplace.id} key={workplace.id}>
                {' '}
                {workplace.name}{' '}
              </MenuItem>
            )
          })}
        </StyledTextField>
      )}
    </Box>
  )
}

export default WorkplaceSelector
