import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Login from 'pages/Login'
import ReservationScheduleCalendar from 'pages/ReservationScheduleCalendar'
import ClientsList from 'pages/ClientsList'
import ClientForm from 'pages/ClientForm'
import SubscriptionAssign from 'pages/SubscriptionAssign'
import SubscriptionAddForm from 'pages/SubscriptionAddForm'
import ClientSubscriptionsList from 'pages/ClientSubscriptionsList'
import { useCurrentUser } from 'utils/UserContext'
import { Logout } from 'pages/Logout'
import WorkplaceList from 'pages/WorkplaceList'
import SubscriptionsList from 'pages/SubscriptionsList'
import { ReservationStatistics } from 'pages/Statistics'
import WorkplaceConfigMenu from 'pages/WorkplaceConfigMenu'

export const AuthorizedRoutes = () => {
  const { currentUser } = useCurrentUser()

  if (currentUser === undefined) return null

  return (
    <Routes>
      <Route index element={<Navigate replace to='calendar' />} />
      <Route path='/clients'>
        <Route path='new' element={<ClientForm />} />
        <Route path='list' element={<ClientsList />} />
      </Route>
      {currentUser.is_superuser && (
        <Route path='/subscriptions'>
          <Route path='new' element={<SubscriptionAddForm />} />
          <Route path='list' element={<SubscriptionsList />} />
        </Route>
      )}
      {currentUser.is_superuser && (
        <Route path='/user_subscriptions'>
          <Route path='new' element={<SubscriptionAssign />} />
          <Route path='list' element={<ClientSubscriptionsList />} />
        </Route>
      )}
      {currentUser.is_superuser && (
        <Route path='/workplaces'>
          <Route path='list' element={<WorkplaceList />} />
          <Route path='configs' element={<WorkplaceConfigMenu />} />
        </Route>
      )}
      {currentUser.is_superuser && (
        <Route path='/statistics'>
          <Route path='reservations' element={<ReservationStatistics />} />
        </Route>
      )}
      <Route path='login' element={<Login />} />
      <Route path='calendar' element={<ReservationScheduleCalendar />} />
      <Route path='logout' element={<Logout />} />
    </Routes>
  )
}

export const UnauthorizedRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate replace to='login' />} />
      <Route path='login' element={<Login />} />
      <Route path='*' element={<Navigate replace to='login' />} />
    </Routes>
  )
}
