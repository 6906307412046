import { getUsersList } from 'api/userApi'
import { useState } from 'react'
import {
	Box,
} from '@mui/material'
import ClientDialog from 'components/ClientDialog'
import { Client } from 'types'
import { useQuery } from 'react-query'
import dayjs from 'dayjs'
import { DataGrid, GridColDef, GridEventListener, GridToolbarQuickFilter } from '@mui/x-data-grid'


const format_date_of_birth = (date_string: string) => {
	let date = dayjs(date_string)
	if (date.isValid()) return date.format('DD/MM')

	return '-'
}

const columns: GridColDef[] = [
	{ field: 'first_name', headerName: 'Имя', width: 150 },
	{ field: 'last_name', headerName: 'Фамилия', width: 150 },
	{ field: 'phone_number', headerName: 'Номер телефона', width: 150 },
	{ field: 'email', headerName: 'Email', width: 200 },
	{ field: 'specialization', headerName: 'Специализация', width: 150 },
	{ field: 'date_of_birth', headerName: 'Дата рождения', width: 150, valueFormatter: (params) => format_date_of_birth(params.value) },
]

export const ClientsList = () => {
	const [selectedClient, setSelectedClient] = useState<Client | null>(null)
	const [dialogOpen, setDialogOpen] = useState<boolean>(false)
	// const [page, setPage] = useState<number>(0)
	// const [rowsPerPage, setRowsPerPage] = useState<number>(10)

	const usersDataQuery = useQuery('clients', getUsersList)

	const handleDialogOpen: GridEventListener<'rowClick'> = (params) => {
		setSelectedClient(params.row)
		setDialogOpen(true)
	}

	// 	const handleChangePage = (event: unknown, newPage: number) => {
	// 		setPage(newPage)
	// 	}

	// 	const handleChangeRowsPerPage = (
	// 		event: React.ChangeEvent<HTMLInputElement>
	// 	) => {
	// 		setRowsPerPage(parseInt(event.target.value, 10))
	// 		setPage(0)
	// 	}

	return (
		<>
			{selectedClient && (
				<ClientDialog
					open={dialogOpen}
					onClose={() => setDialogOpen(false)}
					client={selectedClient}
				/>
			)}
			<Box>
				<Box sx={{ height: "100vh" }}>
					<DataGrid
						rows={usersDataQuery.data?.body || []}
						columns={columns}
						autoPageSize
						disableSelectionOnClick
						initialState={{
							sorting: {
								sortModel: [
									{ field: 'first_name', sort: 'asc' }
								],
							},
						}}
						components={{ Toolbar: GridToolbarQuickFilter }}
						componentsProps={{
							toolbar: {
								variant: 'outlined',
								placeholder: 'Поиск...',
							},
						}}
						onRowClick={handleDialogOpen}
					// rowsPerPageOptions={[15, 25]}
					// onPageSizeChange={handleChangeRowsPerPage}
					// pageSize={rowsPerPage}
					/>
				</Box>
			</Box>
		</>
	)
}

export default ClientsList
