import React, { useEffect, useState } from 'react'
import {
	Dialog,
	DialogContent,
	TextField,
	Divider,
	DialogTitle,
	Typography,
	Tabs,
	Tab,
	Stack,
	Box,
} from '@mui/material'
import styled from 'styled-components'
import { changeUser } from 'api/userApi'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { Workplace } from 'types'
import { useMutation, useQueryClient } from 'react-query'
import { changeWorkplace } from 'api/workplaceApi'

const DialogContentBlock = styled.div`
  min-width: 760px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

const TabPanel: React.FC<{
	value: number
	index: number
	children: React.ReactNode
}> = ({ value, index, children }) => {
	return (
		<Box hidden={value !== index}>
			{value === index && <div>{children}</div>}
		</Box>
	)
}

const WorkplaceDialog: React.FC<{
	workplace: Workplace
	open: boolean
	onClose: any
}> = (props) => {
	const [selectedTab, setSelectedTab] = useState<number>(0)
	const [workplace, setWorkplace] = useState<Workplace>(props.workplace)

	const { enqueueSnackbar } = useSnackbar()

	const queryClient = useQueryClient()

	const handleSave = async () => {
		if (workplace.id === undefined) {
			throw 'Ошибка'
		}

		let workplaceObject = {
			...workplace,
		}

		let result = null

		try {
			result = await changeWorkplace(workplaceObject)
		} catch {
			throw 'Ошибка сети'
		}

		if (result?.ok) {
		} else {
			throw 'Не удалось сохранить данные о рабочем месте'
		}
	}

	const workplaceMutation = useMutation(handleSave, {
		onSuccess: () => {
			enqueueSnackbar('Сохранено', { variant: 'success' })
			queryClient.invalidateQueries('workplaces')
		},
		onError: (error: string) => {
			enqueueSnackbar(error, { variant: 'error' })
		},
	})

	useEffect(() => {
		if (!props.open) {
			setWorkplace({
				id: 0,
				amount: 0,
				price: 0,
				price_per_shift: 0,
				name: '',
			})
		} else if (props.workplace) {
			setWorkplace(props.workplace)
		}
	}, [props.workplace, props.open])

	const handleWorkplaceChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setWorkplace((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}))
	}

	return (
		<>
			<Dialog open={props.open} onClose={props.onClose} maxWidth='xl'>
				<DialogTitle>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Typography fontSize={'large'}>{workplace.name}</Typography>
						<LoadingButton
							loading={workplaceMutation.isLoading}
							onClick={() => workplaceMutation.mutate()}
							variant='contained'
						>
							Сохранить
						</LoadingButton>
					</Box>
				</DialogTitle>
				<Divider />
				<DialogContent style={{ paddingTop: 4 }}>
					<DialogContentBlock>
						<Tabs
							value={selectedTab}
							onChange={(event, newValue) => setSelectedTab(newValue)}
						>
							<Tab label='Общая информация' />
						</Tabs>
						<TabPanel value={selectedTab} index={0}>
							<Stack spacing={2} m={'0 auto'}>
								<Divider></Divider>
								<Box
									sx={{
										display: 'flex',
										width: '100%',
										justifyContent: 'space-between',
									}}
								>
									<TextField
										label='Название'
										fullWidth
										required
										value={workplace.name}
										name='name'
										onChange={handleWorkplaceChange}
										style={{
											marginRight: '30px',
										}}
									/>
									<TextField
										fullWidth
										label='Количество мест'
										required
										name='amount'
										value={workplace.amount}
										onChange={handleWorkplaceChange}
									/>
								</Box>
								<TextField
									label='Цена (час)'
									required
									name='price'
									value={workplace.price}
									onChange={handleWorkplaceChange}
								/>
								<TextField
									label='Цена (смена)'
									required
									name='price_per_shift'
									value={workplace.price_per_shift}
									onChange={handleWorkplaceChange}
								/>
							</Stack>
						</TabPanel>
					</DialogContentBlock>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default WorkplaceDialog
