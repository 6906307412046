import { Reservation, Workplace } from 'types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.tz.setDefault("Asia/Yekaterinburg")
dayjs.extend(utc)

export const createTimeArray = (rowAmount: number) => {
  let result = []
  let workdayStart = 480

  for (let i = 0; i < rowAmount + 1; i++) {
    let currentMinutes = workdayStart + i * 30
    let currentTime = new Date()

    currentTime.setHours(Math.trunc(currentMinutes / 60))
    currentTime.setMinutes(currentMinutes % 60)
    currentTime.setSeconds(0)
    currentTime.setMilliseconds(0)

    let currentTimeMinutes = String(currentTime.getMinutes()).padStart(2, '0')

    result.push(`${currentTime.getHours()}:${currentTimeMinutes}`)
  }
  return result
}

export const createRowGrid = (
  rowAmount: number,
  GridBorderTop: React.ComponentType<any>,
) => {
  return range(rowAmount + 1).map((row_number) => (
    <GridBorderTop
      key={row_number}
      gridRowStart={row_number * 3 + 1}
      gridRowEnd={(row_number + 1) * 3 + 1}
    />
  ))
}

export const createColumnGrid = (
  columnAmount: number,
  GridBorderLeft: React.ComponentType<any>
) => {
  return range(columnAmount).map((column_number) => (
    <GridBorderLeft
      key={column_number}
      gridColumnStart={column_number + 1}
      gridColumnEnd={column_number + 2}
    />
  ))
}

export const range = (n: number) => Array.from(Array(n).keys())

export const populateCalendar = (
  reservations: Array<Reservation>,
  workplaceOptions: Array<Workplace>,
  rowAmount: number,
  columnAmount: number,
  handleDialogOpen: any,
  ReservationCell: React.ComponentType<any>
) => {
  let used_cells = new Array(rowAmount)

  for (let i = 0; i < rowAmount; i++) {
    used_cells[i] = new Array(columnAmount)
  }

  for (let i = 0; i < rowAmount; i++) {
    for (let j = 0; j < columnAmount; j++) {
      used_cells[i][j] = 0
    }
  }

  let calendar_cells: any = []

  reservations.forEach((reservation) => {
    let startDate = dayjs(reservation.reservation_start).tz("Asia/Yekaterinburg")
    let endDate = dayjs(reservation.reservation_end).tz("Asia/Yekaterinburg")

    // let neededRowStart = Math.trunc(
    //   ((startDate.getHours() - 10) * 60 + startDate.getMinutes()) / 30
    // )
    // let neededRowEnd = Math.trunc(
    //   ((endDate.getHours() - 10) * 60 + endDate.getMinutes()) / 30 - 1
    // )
		//
		//
		//

    let neededRowStart = Math.trunc(
      ((startDate.hour() - 8) * 60 + startDate.minute()) / 10
    )
    let neededRowEnd = Math.trunc(
      ((endDate.hour() - 8) * 60 + endDate.minute()) / 10 - 1
    )

    for (let j = 0; j < columnAmount; j++) {
      let allCellsFree = true

      for (let i = neededRowStart; i < neededRowEnd + 1; i++) {
        if (neededRowStart > rowAmount || neededRowEnd > rowAmount) {
          allCellsFree = false
          break
        }

        if (used_cells[i][j] !== 0) allCellsFree = false
      }

      if (allCellsFree) {
        calendar_cells.push(
          <ReservationCell
            key={reservation.id}
            handleDialogOpen={handleDialogOpen}
            workplaceOptions={workplaceOptions}
            userId={reservation.user.id}
            reservationId={reservation.id}
            reservationObject={reservation}
            gridRowStart={neededRowStart + 1}
            gridRowEnd={neededRowEnd + 2}
            gridColumnStart={j + 1}
            gridColumnEnd={j + 2}
          />
        )

        for (let i = neededRowStart; i < neededRowEnd + 1; i++) {
          used_cells[i][j] = 1
        }

        break
      }
    }
  })

  return calendar_cells
}
