import {
	CircularProgress,
	Divider,
	Grid,
	Stack,
	Typography,
} from '@mui/material'
import ClientSelector from 'components/ClientSelector'
import { useState } from 'react'
import WorkplaceSelector from 'components/WorkplaceSelector'
import {
	getSubscriptions,
	getUserSubscriptionsByUser,
	postUserSubscription,
} from 'api/subscriptionApi'
import { TextField, Autocomplete } from '@mui/material'
import UserSubscriptionCard from 'components/UserSubscriptionCard'
import { Client, ReservationSubscription, UserSubscription } from 'types'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import { Box } from '@mui/system'

export const SubscriptionAssign: React.FC = () => {
	const queryClient = useQueryClient()
	const { enqueueSnackbar } = useSnackbar()

	const [selectedClient, setSelectedClient] = useState<Client | null>(null)
	const [selectedWorkplace, setSelectedWorkplace] = useState<number | null>(
		null
	)
	const [selectedSubscription, setSelectedSubscription] = useState<
		ReservationSubscription | undefined
	>(undefined)

	const fetchUserSubscriptions = async (selectedClient: Client | null) => {
		if (selectedClient === undefined || selectedClient === null) return

		let result = await getUserSubscriptionsByUser(selectedClient.id)

		return result
	}

	const userSubscriptionsQuery = useQuery(
		['userSubscriptions', selectedClient],
		() => fetchUserSubscriptions(selectedClient)
	)
	const reservationSubscriptionsQuery = useQuery(
		'reservationSubscriptions',
		getSubscriptions
	)

	const handleSelectedSubscriptionChange = (
		newSelectedSubscription: ReservationSubscription
	) => {
		setSelectedSubscription(newSelectedSubscription)
	}

	const handleWorkplaceChange = (newWorkplace: number | null) => {
		setSelectedSubscription(undefined)
		setSelectedWorkplace(newWorkplace)
	}

	const handleAssigningSubscription = async () => {
		if (!selectedClient) {
			return
		}

		let userSubscriptionObject = {
			user: selectedClient.id,
			reservation_subscription: selectedSubscription?.id,
		}

		let result = await postUserSubscription(userSubscriptionObject)

		return result
	}

	const userSubscriptionMutation = useMutation(handleAssigningSubscription, {
		onSuccess: () => {
			queryClient.invalidateQueries('userSubscriptions')
			enqueueSnackbar('Абонемент выдан успешно', { variant: 'success' })
		},
		onError: () => {
			enqueueSnackbar('Ошибка при выдаче абонемента', { variant: 'error' })
		},
	})

	if (reservationSubscriptionsQuery.data === undefined) return null

	return (
		<Box
			sx={{
				margin: '10px',
			}}
		>
			<Grid container spacing={2} minWidth={'800px'}>
				<Grid item xs={4}>
					<Typography fontSize={18} fontWeight={'light'}>
						Выбор клиента
					</Typography>
					<Stack spacing={2}>
						<Divider></Divider>
						<ClientSelector
							autocompleteValue={selectedClient}
							handleChange={setSelectedClient}
						/>

						{selectedClient && (
							<>
								<Typography>
									Активные абонементы
									{userSubscriptionsQuery.isLoading && (
										<CircularProgress size={15} />
									)}
								</Typography>

								{userSubscriptionsQuery.data?.body && (
									<>
										{userSubscriptionsQuery.data.body.map(
											(userSubscription: UserSubscription) => (
												<UserSubscriptionCard
													userSubscription={userSubscription}
													marginTop={'10px'}
												/>
											)
										)}
									</>
								)}
							</>
						)}
					</Stack>
				</Grid>
				<Grid item xs={4}>
					<Typography fontWeight={'light'} fontSize={18}>
						Выбор абонемента
					</Typography>
					<Stack spacing={2}>
						<Divider />
						<WorkplaceSelector
							fullWidth
							selectedWorkplace={selectedWorkplace}
							handleSelectedWorkplaceChange={handleWorkplaceChange}
						/>
						<Autocomplete
							isOptionEqualToValue={(option, value) => option.id === value.id}
							value={selectedSubscription}
							getOptionLabel={(autocompleteValue) => {
								return `${autocompleteValue.workplace.name} ${autocompleteValue.free_minutes / 60
									}ч`
							}}
							onChange={(event, newValue) => {
								handleSelectedSubscriptionChange(newValue)
							}}
							fullWidth
							disableClearable
							renderInput={(params) => (
								<TextField {...params} label='Абонемент' />
							)}
							options={reservationSubscriptionsQuery.data.body.filter(
								(subscription) =>
									subscription.workplace?.id === selectedWorkplace
							)}
						/>
						<LoadingButton
							loading={userSubscriptionMutation.isLoading}
							variant='contained'
							onClick={() => userSubscriptionMutation.mutate()}
						>
							Сохранить
						</LoadingButton>
						{selectedSubscription && (
							<Box>
								<Typography variant={'h6'}>Описание абонемента</Typography>
								<Divider></Divider>
								<Typography>{`Рабочее место: ${selectedSubscription.workplace.name}`}</Typography>
								<Typography>{`Кол-во часов: ${selectedSubscription.free_minutes / 60
									}`}</Typography>
								<Typography>{`Цена: ${selectedSubscription.price} р.`}</Typography>
								<Typography>{`Длительность: ${selectedSubscription.duration} д.`}</Typography>
							</Box>
						)}
					</Stack>
				</Grid>
			</Grid>
		</Box>
	)
}

export default SubscriptionAssign
