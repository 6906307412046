import { useEffect } from 'react'
import { clearJwtTokens, login } from 'api/jwtApi'
import { useNavigate } from 'react-router'

export const Logout: React.FC = () => {
	const navigate = useNavigate()
  useEffect(() => {
    clearJwtTokens()
		navigate('/login')
  }, [])

  return (
    <>
    </>
  )
}
