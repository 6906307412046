import { Typography } from '@mui/material'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { type Dayjs } from 'dayjs'
import { UserSubscription } from 'types'
import { Button } from '@mui/material'

const CardWrapper = styled(Button)`
  && {
    margin-top: ${(props: { marginTop: string; backgroundColor?: string }) =>
		props.marginTop || '0px'};
    border-radius: 6px;
    justify-content: flex-start;
    align-items: flex-start;
    text-transform: none;
    color: inherit;
    display: flex;
    text-align: left;
    flex-direction: column;
    padding-left: 10px;
    max-width: 100%;
    width: 100%;
    border-color: lightgray;
    background-color: ${(props) => props.backgroundColor || 'white'};
    :hover {
      background-color: #e3f2fd;
    }
  }
`

const formatDate = (date: Dayjs): string => {
	if (date) {
		return dayjs(date).format('DD/MM/YYYY')
	} else {
		return '---'
	}
}

export const UserSubscriptionCard: React.FC<{
	marginTop: string
	onClick?: any
	userSubscription: UserSubscription
	backgroundColor?: string
}> = (props) => {
	const getFormattedRemainingTime = () => {
		let remaining_time_minutes =
			props.userSubscription.free_minutes - props.userSubscription.used_minutes

		return `${Math.floor(remaining_time_minutes / 60)} ч. ${remaining_time_minutes % 60
			} м.`
	}

	return (
		<CardWrapper
			key={props.userSubscription.id}
			marginTop={props.marginTop}
			onClick={props.onClick}
			backgroundColor={props.backgroundColor}
			variant='outlined'
		>
			<Typography>
				{`Тип абонемента: ${props.userSubscription.reservation_subscription.workplace.name}`}
			</Typography>
			<Typography>
				{`Доступно часов: ${props.userSubscription.free_minutes / 60}`}
			</Typography>
			<Typography>{`Остаток: ${getFormattedRemainingTime()}`}</Typography>
			<Typography>
				{`Дата активации: ${formatDate(
					props.userSubscription.activation_date
				)}`}
			</Typography>
			<Typography>
				{`Действует до: ${formatDate(
					props.userSubscription.deactivation_date
				)}`}
			</Typography>
			<Typography>
				{`Дата приобретения: ${formatDate(
					props.userSubscription.acquisition_date
				)}`}
			</Typography>
		</CardWrapper>
	)
}

export default UserSubscriptionCard
