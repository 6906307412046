import { sendAuthorizedRequest } from './jwtApi'
import { BASE_URL } from 'api/config'
import {
  ApiResponse,
	PaymentType
} from 'types'

export async function getPaymentTypes(): Promise<
	ApiResponse<Array<PaymentType>>
> {
	let payment_types_endpoint_url = BASE_URL + `payments/payment_types`

	let result = await sendAuthorizedRequest(payment_types_endpoint_url)

	//let result = await fetch(reservation_endpoint_url)

	return {
		status_code: result.status,
		ok: result.ok,
		body: await result.json(),
	}
}

