import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useReservationStatuses } from 'hooks/useReservationStatuses'

export const ReservationStatusSelector: React.FC<{
	value: number
	onChange: (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		value: number
	) => void
}> = (props) => {
	const reservationStatuses = useReservationStatuses()

	if (!reservationStatuses.data?.body) return null

	return (
		<ToggleButtonGroup
			color='primary'
			fullWidth
			exclusive
			value={props.value}
			onChange={props.onChange}
			size='small'
		>
			<ToggleButton value={reservationStatuses.data.body[0].id}>
				Ожидание клиента
			</ToggleButton>
			<ToggleButton
				value={reservationStatuses.data.body[1].id}
				color={'success'}
			>
				Клиент пришел
			</ToggleButton>
			<ToggleButton value={reservationStatuses.data.body[2].id} color={'error'}>
				Клиент не пришел
			</ToggleButton>
		</ToggleButtonGroup>
	)
}
