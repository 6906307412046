import { Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Reservation } from 'types'
import ReservationDialog from './ReservationDialog'
import { useQuery } from 'react-query'
import { getReservationStatuses } from 'api/reservationApi'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { getClientFullName } from 'utils/userUtils'

dayjs.extend(utc)

interface CellWrapperProps {
	gridRowStart: number
	gridRowEnd: number
	gridColumnStart: number
	gridColumnEnd: number
}

const CellWrapper = styled.div`
  text-align: center;
  grid-row-start: ${(props: CellWrapperProps) => props.gridRowStart};
  grid-row-end: ${(props) => props.gridRowEnd};
  grid-column-start: ${(props) => props.gridColumnStart};
  grid-column-end: ${(props) => props.gridColumnEnd};
  border-radius: 5px;
  border: 1px solid;
  border-color: black;
  height: 100%;
  width: 100%;
  background-color: white;
  opacity: 85%;
  margin-left: 2px;
  margin-top: 2px;
  overflow: hidden;
`

const StyledButton = styled(Button)`
  && {
    height: 100%;
    width: 100%;
    background-color: ${(props: { bgcolor?: string }) =>
		props.bgcolor || 'gray'};
    text-transform: lowercase;
    justify-content: flex-start;
    flex-direction: column;
    font-size: small;
    opacity: 1;
    :hover {
      opacity: 0.9;
      background-color: ${(props: { bgcolor?: string }) =>
		props.bgcolor || 'gray'};
    }
    padding: 0;
  }
`

function getTimeStringFromDate(isoDateString: string): string {
	let currentTime = dayjs(isoDateString).tz('Asia/Yekaterinburg')
	let currentTimeMinutes = String(currentTime.minute()).padStart(2, '0')

	return `${currentTime.hour()}:${currentTimeMinutes}`
}

export const ReservationCell: React.FC<
	CellWrapperProps & {
		handleDialogOpen: any
		reservationObject: Reservation
	}
> = (props) => {
	const reservationStatusesQuery = useQuery(
		'reservationStatuses',
		getReservationStatuses,
		{
			staleTime: Infinity,
			cacheTime: Infinity,
		}
	)

	return (
		<CellWrapper {...props}>
			<StyledButton
				disableElevation
				variant='contained'
				onClick={() => props.handleDialogOpen(props.reservationObject)}
				bgcolor={props.reservationObject.admin_info?.calendar_color || ''}
			>
				<Typography
					variant={'body2'}
					sx={{
						borderBottom: 'solid 3px black',
						width: '100%',
					}}
				>
					{props.reservationObject.creation_type.description}
				</Typography>
				<Typography
					sx={{
						borderBottom: 'solid 3px black',
						width: '100%',
						textTransform: 'capitalize',
					}}
					variant={'body2'}
				>
					{getClientFullName(
						props.reservationObject.user.first_name,
						props.reservationObject.user.last_name,
						props.reservationObject.user.patronymic
					) || props.reservationObject.user.email}
				</Typography>
				<Typography
					variant={'body2'}
					sx={{
						borderBottom: 'solid 3px black',
						width: '100%',
					}}
				>
					{
						reservationStatusesQuery.data?.body?.find((reservationStatus) => {
							return (
								reservationStatus.id ===
								props.reservationObject.admin_info.reservation_status
							)
						})?.description
					}
				</Typography>

				<Typography
					variant={'body2'}
					sx={{
						borderBottom: 'solid 3px black',
						width: '100%',
					}}
				>
					{`${getTimeStringFromDate(
						props.reservationObject.reservation_start
					)} - 
             ${getTimeStringFromDate(props.reservationObject.reservation_end)}`}
				</Typography>
				{props.reservationObject.admin_info?.commentary && (
					<Typography
						variant={'body2'}
						sx={{
							borderBottom: 'solid 3px black',
							width: '100%',
						}}
					>
						{props.reservationObject.admin_info?.commentary}
					</Typography>
				)}
			</StyledButton>
		</CellWrapper>
	)
}

export default ReservationCell
